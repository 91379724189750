import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import AddLevel from "./AddLevel";
import { Loader, Pagination, AddBtn } from "../../common";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import SingleLevel from "./Details";
import { responseNotification } from "../../../utils/notify";

const MembershipList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [userType, setUserType] = useState("USER");
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedLevel, setSelectedLevel] = useState(undefined);
  const [selectedLevelForEdit, setSelectedLevelForEdit] = useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [levelData, setLevelData] = useState<any>({
    loading: false,
    data: null,
  });

  const getLevels = useCallback(async () => {
    setLevelData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/membership/level?userType=${userType}&page=0` +
          (key ? `&key=${key}` : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLevelData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setLevelData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [userType, key, page, limit]);

  
  const onLevelRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/membership/level`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Level Remove Successfully", "success");
            getLevels();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/membership/level`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getLevels();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  }; 
  useEffect(() => {
    getLevels();
  }, [getLevels]);

  const onClose = () => {
    getLevels();
    setVisible(undefined);
    setSelectedLevel(undefined);
    setSelectedLevelForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  return (
    <>
      <BreadCrumb
        title="Membership Level List"
        subTitle={`${
          levelData?.data?.totalElements ||
          levelData?.data?.merchantTypes?.length ||
          0
        } Level(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {levelData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">User Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Point Amount</th>
                      <th scope="col">Expiration Days</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {levelData?.data?.membershipLevels?.length ? (
                      levelData?.data?.membershipLevels?.map(
                        (level: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedLevel(level)}
                          >
                            <td className="flex items-center gap-2">
                              <Avatar
                                size={45}
                                src={level?.logo}
                                shape="square"
                                className="border"
                              />
                              <span className="font-medium text-gray-500">
                                {level?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {level?.description?.slice(0, 70)}
                              </span>
                            </td>
                            <td>{level?.userType}</td>
                            <td>{level?.amount}</td>
                            <td>{level?.pointAmount}</td>
                            <td>{level?.membershipExpirationInDays}</td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!level?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(level?.id, val);
                                }}
                              />
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedLevelForEdit(level)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={level?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onLevelRemove(level?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(level?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...levelData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Level"
            : selectedLevelForEdit
            ? "Edit Level"
            : "Level Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedLevel || selectedLevelForEdit}
        width={450}
      >
        {visible || selectedLevelForEdit ? (
          <AddLevel onCloseMethod={onClose} visibleData={selectedLevelForEdit} />
        ) : (
          <SingleLevel SingleLevel={selectedLevel} />
        )}
      </Drawer>
    </>
  );
};

export default MembershipList;
