import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CorporateLayout from "../Layout";
import { getPage, getParamValue } from "../../../utils/index";
import { Button, DatePicker, Empty, Form, Input } from "antd";
import Pagination from "../../common/Pagination/Pagination";
import axios from "axios";
import builkcsv from "../../../demo-files/topUp_failed_corrected.xlsx";
import * as xlsx from "xlsx";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "../../../styles/tailwind/List.module.css";
import Loader from "../../common/Loader";
import { useNavigate, useParams } from "react-router";
import { DownloadOutlined, FileDoneOutlined } from "@ant-design/icons";

const CorporateTopupRequest = ({ params }: any) => {
  const { RangePicker } = DatePicker;
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const { corporateId } = corporateInfo?.id;
  const navigate = useNavigate();
  const route = useParams();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(16);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [topUpData, setTopUpData] = useState(new Array());
  const [showSearch, setShowSearch] = useState<any>(true);
  const CompanyBlance = corporateInfo?.balance;
  const [open, setOpen] = React.useState(true);
  const [instituteName, setInstituteName] = useState<any>(corporateInfo?.name);
  const [companyComment, setCompanyComment] = useState<any>();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  
  const [loading, setLoading] = useState(false);
  const [topUpResponseMessage, setTopUpResponseMessage] = useState("");
  const [batchId, setBatchId] = useState("");

  const postTopUp = async () => {
    setLoading(true);
    const topUpRequestsToBeSent = {
      instituteName: instituteName,
      companyComment: companyComment,
      companyId: corporateInfo?.ownerId,
      topUpRequests: topUpData,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_TOPUP_API}/admin/topup-request/company/`,
        topUpRequestsToBeSent,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((e) => {

        setTopUpResponseMessage(
          "Top up failed. Server error. Error Code: " +
            e.response.data.status +
            ", error: " +
            e.response.data.error +
            ". Please try again later..."
        );
        setLoading(false);
      });
    if (response && response.status == 200) {
      setLoading(false);
      setBatchId(response.data.companyTopUp.id);
      setTopUpResponseMessage(
        "Your topup request for " +
          response.data.companyTopUp.numberOfRequest +
          " numbers of total " +
          response.data.companyTopUp.totalAmount +
          " taka waiting for approval."
      );
      navigate(`/corporates/${corporateInfo?.id}/approved`);
    } else {
      setLoading(false);
      setTopUpResponseMessage(
        "Something went wrong. Please, try again later...."
      );
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "mobileNumber", headerName: "Mobile Number", width: 250 },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "operator", headerName: "Operator", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
  ];



  const makeLowercaseFirstLetterAndRemoveSpaceFromKeys = (obj: any) =>
    Object.keys(obj).reduce((acc: any, key: any) => {
      acc[(key.charAt(0).toLowerCase() + key.slice(1)).replace(/\s/g, "")] =
        obj[key];
      return acc;
    }, {});

  const readFileAndPostTopUp = (e: any) => {
    e.preventDefault();
    if (e?.target?.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        let res = new Array(json.length);
        for (let index in json) {
          res[index] = makeLowercaseFirstLetterAndRemoveSpaceFromKeys(
            json[index]
          );
        }

        setTopUpData(res);
        const fileName = (
          document.getElementById("topUpFileUpload") as HTMLInputElement
        )?.value
          ?.split("\\")
          .pop();

        //postTopUp(fileName, res)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const simpleFileDownload = () => {
    window.location.href = `${builkcsv}`;
  };
  const goToDetail = () => {
    //router.replace(`/topup-history/` + batchId)
    navigate(`/topup-list/${batchId}/details`);
  };
 




  return (
    <React.Fragment>
      <CorporateLayout
       corporateId={(route as any)?.corporateId as string}
        title="Topup History List"
        subTitle="Topup History List"
      >
        <>

          <div className="content-body rounded-2xl">
            <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
              <div className={styles.contentWrapper}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                      className={
                        showSearch ? `content-body-withSearch` : `content-body`
                      }
                    >
{/* topup request start */}
<div className="w-full py-10 h-screen px-2">
          {topUpData.length === 0 ? (
            <>
              <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden ">
                <div className="w-full row-span-2 md:col-span-4">
                  <div className="w-full row-span-2 md:col-span-4">
                    <div className="p-4 border-b-2">
                      <span className="text-lg font-bold text-gray-600">
                        Upload your attachments
                      </span>
                    </div>

                    {!loading && topUpResponseMessage?.length === 0 ? (
                      <div className="p-3">
                        <div className="mb-2">
                        
                            <>
                              <span>Attachments </span>
                              <form>
                                <Input
                                  type="file"
                                  className="mb-4"
                                  placeholder="Type product name here"
                                  id="topUpFileUpload"
                                  onChange={readFileAndPostTopUp}
                                />
                              </form>

                              <div className="flex justify-between items-center text-gray-400">
                                <span>
                                  Accepted file type: .xlsx files only
                                </span>
                              </div>
                              <div className="d-flex">
                                <Button
                                  type="dashed"
                                  shape="round"
                                  danger
                                  onClick={simpleFileDownload}
                                >
                                  <DownloadOutlined />
                                  Sample File
                                </Button>
                              </div>
                            </>
                        
                        </div>
                      </div>
                    ) : (
                      <p
                        style={{
                          marginTop: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {topUpResponseMessage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {topUpData.length ? (
                <div
                  className="overflow-auto"
                  style={{
                    width: "100%",
                    height: "650px",
                    marginTop: 15,
                    backgroundColor: "rgb(173 173 173 / 12%)",
                  }}
                >
                  <Form
                    name="control-hooks"
                    form={form} // like ref
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={postTopUp}
                    layout="vertical"
                    autoComplete="off"
                  >
                    <div className="grid grid-cols-2 gap-x-6">
                      
                      <div>
                        <Form.Item
                          label="Institute Name"
                          name="instituteName"
                          rules={[
                            {
                              required: true,
                              message: "Institute Name is Required!",
                            },
                          ]}
                          initialValue={instituteName}
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Enter Institute Name"
                            onKeyUp={(e) => setInstituteName((e.target as HTMLInputElement).value)}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          label="Comment"
                          name="comment"
                          rules={[
                            {
                              required: true,
                              message: "Comment is Required!",
                            },
                          ]}
                        >
                          <Input
                            id="name"
                            type="text"
                            placeholder="Enter comment"
                            onKeyUp={(e) => setCompanyComment((e.target as HTMLInputElement).value)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <table className={styles.mainTable}>
                      <thead className="bg-white border-b">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Operator</th>
                          <th scope="col">Type</th>
                        </tr>
                      </thead>

                      <tbody>
                        {topUpData?.length ? (
                          topUpData?.map((topUp: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                            >
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {topUp?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {topUp?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {topUp?.amount}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {topUp?.operator}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {topUp?.type}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <Empty />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="p-10">
                      <Button
                        disabled={loading}
                        loading={(loading ? "loading" : undefined) as any}
                        type="primary"
                        htmlType="submit"
                        className="add-submit-btn text-center mr-2"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                  <div>
                    {/* <Button onClick={postTopUp} disabled={loading}>
                  Top Up 
                </Button> */}
                  </div>
                </div>
              ) : (
                ""
                // <h3>Please check before you proceed</h3>
              )}
            </>
          )}
        </div>
{/* topup request end */}

                    </div>
                  </div>
                </div>

        
              </div>
            </div>
          </div>
        </>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateTopupRequest;
