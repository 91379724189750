import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, { ReactElement, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AutoComplete, Avatar, Button, Drawer, Empty, Form, Input, Select, Spin, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue, jcTypeArray } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import _, { debounce } from "lodash";
import { isAllowedService } from "../../../utils/services";
import { responseNotification } from "../../../utils/notify";
import { AddBtn, Loader, LoaderFull, Pagination } from "../../common";
import SingleVehivleType from "./Details";
import AddVehivleType from "./AddVehicleFeature";
const VehicleFeaturesList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [isActive, setIsActive] = useState<any>(true);
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [selectedVehicleFeatures, setSelectedVehicleFeatures] =
    useState(undefined);
  const [selectedVehicleFeaturesForEdit, setSelectedVehicleFeaturesForEdit] =
    useState(undefined);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [vehicleFeaturesData, setVehicleFeaturesData] = useState<any>({
    loading: false,
    data: null,
  });
  const [vehicleOptions, setVehicleOptions] = useState({
    loading: false,
    list: [],
  });
  const isParent = "false";
  const getVehicleFeaturesAll = useCallback(async () => {
    setVehicleFeaturesData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(`${encodedUri}/admin/vehicle/all-features?isActive=${isActive}`+
        (key ? `&vehicleTypeId=${key}` : ``), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setVehicleFeaturesData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setVehicleFeaturesData({ loading: false, data: [] });
        console.error("Page: Error", err);
      });
  }, [isActive,key,limit, page]);
  const getVehicleTypeOptions = useCallback(
    async (key: any) => {
      setVehicleOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(`${encodedUri}/admin/vehicle-type/all?vehicleType=${key}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setVehicleOptions({
            loading: false,
            list: res.data?.vehicleTypes?.map(
              (vehicle: { vehicleType: any,id:any }) => ({
                label: vehicle.vehicleType,
                value: vehicle.id,
              })
            ),
          });
        })
        .catch((err) => {
          setVehicleOptions({ loading: false, list: [] });
          console.error("Vehicles: Error", err);
        });
    },
    [key, page, limit]
  );
  const onStatusChange = async (id: any, val: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/vehicle-type
`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            isActive: val,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getVehicleFeaturesAll();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "vehicle") getVehicleTypeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getVehicleTypeOptions]);

  useEffect(() => {
    if (showSearch) {
      getVehicleTypeOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getVehicleFeaturesAll();
  }, [getVehicleFeaturesAll]);
  const reseAllFieldData = () => {
    form?.resetFields();
    setKey("");
    setType("");
    setIsActive(undefined);
  };

  const onClose = () => {
    setVisible(undefined);
    setSelectedVehicleFeatures(undefined);
    setSelectedVehicleFeaturesForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Vehicle Features List"
        subTitle={`${
          vehicleFeaturesData?.data?.totalElements ||
          vehicleFeaturesData?.data?.vehicleTypes?.length ||
          0
        } Type(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "vehicle")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={vehicleOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  vehicleOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={vehicleOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`content-body`}>
              {confirmLoading && <LoaderFull />}
              {vehicleFeaturesData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Value</th>
                      <th scope="col">Vehicle Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {vehicleFeaturesData?.data?.vehicleFeature?.length ? (
                      vehicleFeaturesData?.data?.vehicleFeature?.map(
                        (vehicle: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                          >
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.value}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {vehicle?.vehicleTypeId}
                              </span>
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={vehicle?.isActive}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onStatusChange(vehicle?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedVehicleFeaturesForEdit(vehicle)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={
          visible
            ? "Add Vehicle Featur"
            : selectedVehicleFeaturesForEdit
            ? "Edit Vehicle Featur"
            : "Vehicle Featur Details"
        }
        placement="right"
        onClose={onClose}
        open={
          visible || selectedVehicleFeatures || selectedVehicleFeaturesForEdit
        }
        width={450}
      >
        {visible || selectedVehicleFeaturesForEdit ? (
          <AddVehivleType
            onCloseMethod={onClose}
            visibleData={selectedVehicleFeaturesForEdit}
          />
        ) : (
          <SingleVehivleType VehivleTypeDetails={selectedVehicleFeatures} />
        )}
      </Drawer>
    </>
  );
};

export default VehicleFeaturesList;
