import {
  faBiking,
  faBullhorn,
  faCar,
  faClock,
  faClone,
  faCopy,
  faCube,
  faFilm,
  faGift,
  faGifts,
  faHandshake,
  faImages,
  faLaptop,
  faList,
  faListNumeric,
  faListUl,
  faMapLocationDot,
  faMapMarker,
  faShop,
  faShoppingBasket,
  faSitemap,
  faTicket,
  faTruck,
  faUpload,
  faUser,
  faUsers,
  faUserShield,
  faBell,
  faComments,
  faEnvelopeOpen,
  faAlignCenter,
  faAlignLeft,
  faPager,
  faDiagramNext,
  faHandsHelping,
  faComment,
  faCreditCard,
  faUserTie,
  faObjectUngroup,
  faBuilding,
  faHand,
  faGears,
  faGear,
  faSliders,
  faLink,
  faTruckFast,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";

import MerchantUserManagementPage from "../pages/merchant-administration/index";
import MerchantUserGroupPage from "../pages/merchant-administration/merchant-user-group";
import MerchantServicePage from "../pages/merchant-administration/services";
import MerchantServiceGroupPage from "../pages/merchant-administration/groups";

import UserManagementPage from "../pages/administration";
import { ServiceType } from "../type/service";
import ServiceGroupPage from "../pages/administration/groups";
import UserServicePage from "../pages/administration/services";
import DeliveryHeroPage from "../pages/delivery";
import pathaoStorePage from "../pages/delivery/pathao-store";
import DashboardPage from "../pages";
import DriverDetailsPage from "../pages/delivery/drivers/details";
import OrderPage from "../pages/orders";
import GroupByListPage from "../pages/group-buy/index";
import SingleGroupPage from "../pages/group-buy/details";
import OrderImagePage from "../pages/orders-image";
import ReturnOrderPage from "../pages/orders/returnOrder";
import MergeOrderPage from "../pages/merge-order/index";
import MergeOrderCreatePage from "../pages/merge-order/create-merge-order";
import MergeOrderEditPage from "../pages/merge-order/edit-merge-order";
import SingleMergeOrderPage from "../pages/merge-order/details";
import RevenueHistoryPage from "../pages/orders/revenueHistory";
import ShoppingMallPage from "../pages/shopping-mall";
import ShoppingMallDetailsPage from "../pages/shopping-mall/details";
import ShopPage from "../pages/shops";
import ShopDetailsPage from "../pages/shops/details";
import ShopOrderPage from "../pages/shops/orders";
import CampaignOrderPage from "../pages/promotions/campaign/orders";
import ShopProductOutOfStockPage from "../pages/shops/products/out-of-stock";
import ShopBrandsPage from "../pages/shops/brands";
import BannerPage from "../pages/banners";
import CountryPage from "../pages/country";
import AreaPage from "../pages/area";
import AreaBulkUploadPage from "../pages/area/bulk-upload";
import StateBulkUploadPage from "../pages/country/state-bulk-upload";
import CityBulkUploadPage from "../pages/country/city-bulk-upload";
import SubUrbBulkUploadPage from "../pages/suburb/bulk-upload";
import SuburbPage from "../pages/suburb";
import CountryStatePage from "../pages/country/country-state";
import CountryCityPage from "../pages/country/country-city";
import ShortURLPage from "../pages/short-url";
import AddOnsPage from "../pages/addons";
import ShopAddPage from "../pages/shops/add";
import ProductPage from "../pages/products";
import TpUpdateProductPage from "../pages/products/tp-update-product";
import ProgramPage from "../pages/programs/program";
import MainMenuPage from "../pages/main-menu";
import CurriculumPage from "../pages/curriculum";
import DisciplinePage from "../pages/discipline";
import CourseSyllabusPage from "../pages/course-syllabus";
import CourseQuizPage from "../pages/course-quiz";
import EContentPage from "../pages/econtent";
import ChapterPage from "../pages/chapter";
import CoursePage from "../pages/course";
import CourseAddPage from "../pages/course/add";
import TeacherPage from "../pages/teacher";
import TeacherAddPage from "../pages/teacher/add";
import BrandPage from "../pages/brands/brand";
import BrandDetailsPage from "../pages/brands/details";
import BrandProductPage from "../pages/brands/brand-product";
import BusinessTypePage from "../pages/businessType/index";
import serviceListPage from "../pages/serviceType/service-list";
import serviceTypePage from "../pages/serviceType/index";
import CategoryPage from "../pages/categories";
import CategoryProductPage from "../pages/categories/categry-product";
import ProductDetailsPage from "../pages/products/details";
import ProductEditPage from "../pages/products/edit";
import ProductAddPage from "../pages/products/create";
import BookAddPage from "../pages/book/create";
import BookEditPage from "../pages/book/edit";
import BagPage from "../pages/bags";
import BagAddPage from "../pages/bags/create";
import BagEditPage from "../pages/bags/edit";
import SingleOrderPage from "../pages/orders/details";
import SingleImageOrderPage from "../pages/orders-image/details";
import CategoryDetailsPage from "../pages/categories/details";
import ShopProductPage from "../pages/shops/products";
import ShopProductDetailsPage from "../pages/shops/products/details";
import ShopCategoryPage from "../pages/shops/categories";
import ShopTagPage from "../pages/shops/tags";
import ShopShippingPackageListPage from "../pages/shops/shippingPackage/index";
import ShopShippingPackageAddPage from "../pages/shops/shippingPackage/add";
import ShopShippingSettingPage from "../pages/shops/shippingSetting/index";
import ShopShippingProfilePage from "../pages/shops/shipping-profile/index";
import MyShippingProfileDetailsPage from "../pages/shops/shipping-profile/my-shipping-profile-details";
import ShopShippingProfileCreatePage from "../pages/shops/shipping-profile/create";
import MyAddRateCreatePage from "../pages/shops/shipping-profile/my-add-rate";
import MyShippingZonePage from "../pages/shops/shipping-profile/my-shipping-zone";
import MyCustomShippingPage from "../pages/shops/shipping-profile/my-custom-shipping";
import ShopShippingZonePage from "../pages/shops/shippingZone";
import ShopAddRatePage from "../pages/shops/addRate";
import ShopPickupZoneListPage from "../pages/shops/pickupZone/index";
import ShopOrderDetailsPage from "../pages/shops/orders/details";
import ShopProductAddPage from "../pages/shops/products/add";
import ShopProductBulkUploadPage from "../pages/shops/products/bulkupload";
import ShopProductBulkUpdatePage from "../pages/shops/products/buklupdate";
import ShopProductEditPage from "../pages/shops/products/edit";
import TimeSlotPage from "../pages/delivery/time-slot";
import VehicleTypePage from "../pages/delivery/vehicle-type";
import VehicleTypeAddPage from "../pages/content/pages/add";
import ServiceLocationPage from "../pages/delivery/service-location";
import ServiceZonePage from "../pages/delivery/service-zone";
import UserPage from "../pages/users";
import UserDetailsPage from "../pages/users/details";
import TagPage from "../pages/tags";
import BookPage from "../pages/book";
import CampaignPage from "../pages/promotions/campaign";
import PointConfgPage from "../pages/referral/point-confg";
import RevenueDistributionConfgPage from "../pages/config/revenue-distribution-config";
import DefaultDeliveryConfgPage from "../pages/config/default-delivery-config";
import DefaultDeliveryConfgCreatePage from "../pages/config/default-delivery-config-create";
import CampaignBrandPage from "../pages/promotions/campaign/brand";
import CampaignProductPage from "../pages/promotions/campaign/products/index";
import CampaignOrderDetailsPage from "../pages/promotions/campaign/orders/details";
import CampaignShopsPage from "../pages/promotions/campaign/shops";
import GlobalProductPage from "../pages/global-product";
import GlobalProductDetailsPage from "../pages/global-product/details";
import GlobalProductAddPage from "../pages/global-product/create";
import GlobalProductEditPage from "../pages/global-product/edit";
import CampaignDetailsPage from "../pages/promotions/campaign/details";
import OrderCreatePage from "../pages/orders/create";
import MyTransactionPage from "../pages/reports/transactions";
import CompanyBlanceHistoryPage from "../pages/finance/company-blance-history";
import CompanyBlanceDepositPage from "../pages/finance/company-blance-deposit";
import CompanyTopUpHistoryPage from "../pages/reports/company-topup-history";
import CompanyTopUpDetailsPage from "../pages/reports/topup-details";
import MySettlementPage from "../pages/reports/my-settlements";
import VRHistoryPage from "../pages/reports/vr-history";
import DeliveryReportPage from "../pages/reports/delivery-report";
import RevenueReportPage from "../pages/reports/revenue-report";
import VatTaxDeliveryReportPage from "../pages/reports/delivery-vat-tax";
import SalesReportPage from "../pages/reports/sales-report";
import OrderVatTaxPage from "../pages/reports/order-vat-tax";
import MerchantPage from "../pages/merchants";
import MerchantProductPage from "../pages/merchants/products";
import MerchantShopPage from "../pages/merchants/shops";
import MerchantDetailsPage from "../pages/merchants/details";
import ThirdLevelCategoryPage from "../pages/categories/third-level";
import SecondLevelCategoryPage from "../pages/categories/sub-categories";
import PromoCodePage from "../pages/promotions/campaign/promo-codes";
import PromoCodeUsesUserPage from "../pages/promotions/promo/users/index";
import CampaignGroupsPage from "../pages/promotions/groups/index";
import SMSNotificationPage from "../pages/notifications/sms";
import EmailNotificationPage from "../pages/notifications/email";
import PushNotificationPage from "../pages/notifications/index";
import FileUploaderPage from "../pages/file-uploader"; 
import AdvertisePage from "../pages/promotions/campaign/advertise";
import GiftCardTemplatePage from "../pages/promotions/gift-card-template/index";
import GiftCardTemplateCategoryPage from "../pages/promotions/gift-card-template-category/index";
import DriverOrdersPage from "../pages/delivery/drivers/orders";
import DriverTransactionPage from "../pages/delivery/drivers/transactions";
import DriversCashCollectPage from "../pages/delivery/drivers/cash-collect";
import DriverVehicleInfoPage from "../pages/delivery/drivers/vehicle-info";
import DriverUnsettleOrderPage from "../pages/delivery/drivers/unsettle-orders";
import DriverLocationHistoryPage from "../pages/delivery/drivers/location-history";
import VehicleListPage from "../pages/vehicles/index";
import VehicleAddPage from "../pages/vehicles/add";
import VehicleTypeListPage from "../pages/vehicles/Types/index";
import VehicleDetailsPage from "../pages/vehicles/details";
import VehicleBrandListPage from "../pages/vehicles/brand/index";
import VehicleBrandModelListPage from "../pages/vehicles/brand/brandModel";
import FuelTypeListPage from "../pages/vehicles/fuelType/index";
import FuelGradeListPage from "../pages/vehicles/fuelGrade/index";
import VehicleFeatureListPage from "../pages/vehicles/vehicleFeature/index";
import BrtaListPage from "../pages/vehicles/brta/index";
import ContentCategoryPage from "../pages/content/pages";
import ContentPage from "../pages/content";
import ContentDetailsPage from "../pages/content/details";
import ContentAddPage from "../pages/content/add";
import MerchantAddPage from "../pages/merchants/add";
import NeighborhoodPage from "../pages/neighborhood";
import NeighborhoodAddPage from "../pages/neighborhood/neighborhoodAdd";
import NeighborhoodDetailsPage from "../pages/neighborhood/detailsViewNighborhood";
import ViewAllNeighborhoodPage from "../pages/neighborhood/viewAllNeighborhood";
import CorporatePage from "../pages/corporates";
import CorporateDetailsPage from "../pages/corporates/details";
import CorporateEmployeeGroupPage from "../pages/corporates/employee-group";
import CorporateEmployeepage from "../pages/corporates/employees";
import CorporateTopupHistoryPage from "../pages/corporates/topup";
import CorporateTopupRequestApprovedPage from "../pages/corporates/topup/approved-list";
import CorporateTopupRequestApprovedDetailsPage from "../pages/corporates/topup/request-topup-details";
import CorporateBlanceHistoryPage from "../pages/corporates/blance/index";
import CorporateTopUpRequestPage from "../pages/corporates/topup/topup-request";
import CorporateTopupDetailsPage from "../pages/corporates/topup/details";
import FeedbackPage from "../pages/feedback";
import JachaiAppVersionPage from "../pages/app-version/index";
import MerchantAppVersionPage from "../pages/app-version/merchant-app";
import FeedbackTypePage from "../pages/feedback/types";
import FeedbackDetailsPage from "../pages/feedback/details";
import PaymentMethodPage from "../pages/payment-method";
import KeywordPage from "../pages/keywords";
import PopularKeywordPage from "../pages/keywords/popular";
import ProductKeywordPage from "../pages/keywords/product-keywords";

import ContentCategoryAddPage from "../pages/content/pages/add";
import ContentCategoryDetailsPage from "../pages/content/pages/details";
import MerchantRecruiterPage from "../pages/merchants/recruiters";
import MerchantTypePage from "../pages/merchants/types";
import MembershipLevelPage from "../pages/merchants/membership-level/index";
import CampaignProductAssignPage from "../pages/promotions/campaign/products/assign";
import CampaignAddPage from "../pages/promotions/campaign/add";
import RequestedProductPage from "../pages/requested-products";
import MerchantRequestedProductEdit from "../pages/merchants/requested-products/edit";
import MerchantRequestedProductPage from "../pages/merchants/requested-products";
import UrlCheckerPage from "../pages/url-checker";
import DeliveryShippingPage from "../pages/delivery-shipping/index";
import DeliveryCarrierPage from "../pages/delivery-shipping/devliver-carrier";
import PickupZonePage from "../pages/delivery-shipping/pickup-zone-list";
import ManualShippingOrdersPage from "../pages/delivery-shipping/index";
import ShippingRatesPage from "../pages/delivery-shipping/shipping-rates";
import ShippingProfilePage from "../pages/delivery-shipping/shipping-profile";
import ProcessingTimePage from "../pages/delivery-shipping/processing-time";
import ShippingZoneListPage from "../pages/delivery-shipping/shipping-zone-list";
import ShippingZonePage from "../pages/delivery-shipping/shipping-zone";
import ShippingPlacePage from "../pages/delivery-shipping/shipping-place";
import AddPackagePage from "../pages/delivery-shipping/add-package";
import AddRatePage from "../pages/delivery-shipping/add-rate";

export const isAllowedService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const listfromApi: ServiceType[] = JSON.parse(services);
    const data = listfromApi.find((itemfromApi) => {
      return itemfromApi.serviceName === name;
    });
    if (data) {
      return true;
    }
  }
  return false;
};

export const allowedActions = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.filter((item) => item.serviceName === name);
    if (data.length > 0) {
      data.map((d) => d.action);
    }
  }
  return [];
};

export const isEditableService = (name: string) => {
  const services = localStorage.getItem("services");

  if (services) {
    const list: ServiceType[] = JSON.parse(services);
    const data = list.find(
      (item) => item.serviceName === name && item.action === "all"
    );

    if (data) {
      return true;
    }
  }
  return false;
};

export const getAllServices = () => {
  const services = localStorage.getItem("services");
  if (services) {
    return JSON.parse(services);
  }
  return [];
};

type AdminService = {
  name: string;
  path?: string;
  icon?: any;
  element?: any;
  sub?: AdminService[];
  isParent?: boolean;
};

export const adminServices: AdminService[] = [
  //Dashboard routes
  {
    name: "Dashboard",
    path: "/",
    icon: faLaptop,
    element: DashboardPage,
  },

  // Orders & Reviews Routes
  {
    name: "Orders & Reviews",
    path: "/orders",
    icon: faCopy,
    sub: [
      {
        name: "List",
        path: "/",
        icon: faCopy,
        element: OrderPage,
        isParent: true,
      },
      {
        name: "Return-Order",
        path: "/return-order",
        icon: faCopy,
        element: ReturnOrderPage,
        isParent: true,
      },
      {
        name: "Order by Images",
        path: "/order-image",
        icon: faCopy,
        element: OrderImagePage,
        isParent: true,
      },
      {
        name: "Merge Order",
        path: "/merge-order",
        icon: faCopy,
        element: MergeOrderPage,
        isParent: true,
      },
      {
        name: "Merge Order Create",
        path: "/create-merge-order",
        element: MergeOrderCreatePage,
      },
      {
        name: "Merge Order Edit",
        path: "/:orderId/edit",
        element: MergeOrderEditPage,
      },
      {
        name: "Merge Order Details",
        path: "/merge/:orderId/details",
        element: SingleMergeOrderPage,
      },
      {
        name: "Image Order Details",
        path: "/order-image/:orderId/details",
        element: SingleImageOrderPage,
      },
      {
        name: "Details",
        path: "/:orderId/details",
        element: SingleOrderPage,
      },
      
      {
        name: "Manual Order",
        path: "/create",
        element: OrderCreatePage,
      },
      {
        name: "Revenue History",
        path: "/revenue-history",
        icon: faCopy,
        element: RevenueHistoryPage,
        isParent: true,
      },
      {
        name: "Extra",
      },
    ],
  },
  // Group buy Routes
  {
    name: "Group Buy",
    path: "/groupbuy",
    icon: faCopy,
    sub: [
      {
        name: "Group List",
        path: "/",
        icon: faShoppingBasket,
        element: GroupByListPage,
        isParent: true,
      },
      {
        name: "Group Details",
        path: "/:groupId/details",
        element: SingleGroupPage,
      },
    ],
  },
  // Shops Routes
  {
    name: "Shops",
    path: "/shops",
    icon: faShop,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShop,
        element: ShopPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: ShopAddPage,
      },
      {
        name: "Edit",
        path: "/:shopId/edit",
        element: ShopAddPage,
      },
      {
        name: "Delete",
        path: "/:shopId/delete",
      },
      {
        name: "Details",
        path: "/:shopId/details",
        element: ShopDetailsPage,
      },
      {
        name: "Orders",
        path: "/:shopId/orders",
        element: ShopOrderPage,
      },
      {
        name: "Orders Details",
        path: "/:shopId/orders/:orderId/details",
        element: ShopOrderDetailsPage,
      },
      {
        name: "Out-Of-Stock",
        path: "/:shopId/out-of-stock",
        element: ShopProductOutOfStockPage,
      },
      {
        name: "Brands",
        path: "/:shopId/brands",
        element: ShopBrandsPage,
      },
      {
        name: "Products",
        path: "/:shopId/products",
        element: ShopProductPage,
      },
      {
        name: "Product Details",
        path: "/:shopId/products/:slug/details",
        element: ShopProductDetailsPage,
      },
      {
        name: "Product Add",
        path: "/:shopId/products/create",
        element: ShopProductAddPage,
      },
      {
        name: "Product Bulkupload",
        path: "/:shopId/products/bulkupload",
        element: ShopProductBulkUploadPage,
      },
      {
        name: "Product Bulkupdate",
        path: "/:shopId/products/bulkupdate",
        element: ShopProductBulkUpdatePage,
      },
      {
        name: "Product Edit",
        path: "/:shopId/products/:slug/edit",
        element: ShopProductEditPage,
      },
      {
        name: "Categories",
        path: "/:shopId/categories",
        element: ShopCategoryPage,
      },
      {
        name: "Tags",
        path: "/:shopId/tags",
        element: ShopTagPage,
      },
      {
        name: "Package List",
        path: "/:shopId/package-list",
        element: ShopShippingPackageListPage,
      },
      {
        name: "Package Add",
        path: "/:shopId/package-add",
        element: ShopShippingPackageAddPage,
      },
      {
        name: "Package Edit",
        path: "/:shopId/package/:packageId/edit",
        element: ShopShippingPackageAddPage,
      },
      {
        name: "Shipping-Setting",
        path: "/:shopId/shipping-setting",
        element: ShopShippingSettingPage,
      },
      {
        name: "Shipping-Profile",
        path: "/:shopId/my-shipping-profile",
        element: ShopShippingProfilePage,
      },
      {
        name: "Shipping-Profile-details",
        path: "/:shopId/my-shipping-profile-details/:profileId",
        element: MyShippingProfileDetailsPage,
      },
      {
        name: "Shipping-Profile-Create",
        path: "/:shopId/my-shipping-profile-create",
        element: ShopShippingProfileCreatePage,
      },
      {
        name: "Custom-Shipping-Zone",
        path: "/:shopId/my-shipping-zone",
        element: MyShippingZonePage,
      },

      {
        name: "Custom-Shipping",
        path: "/:shopId/my-custom-shipping",
        element: MyCustomShippingPage,
      },
      {
        name: "add-rate",
        path: "/:shopId/shop-shippingprofile-add-rate/:zoneId",
        element: MyAddRateCreatePage,
      },
      {
        name: "Shipping-Add-Rate",
        path: "/:shopId/my-add-rate",
        element: MyAddRateCreatePage,
      },
      {
        name: "Shipping-Zone",
        path: "/:shopId/shipping-zone",
        element: ShopShippingZonePage,
      },
      {
        name: "add-rate",
        path: "/:shopId/shop-add-rate/:zoneId",
        element: ShopAddRatePage,
      },
      {
        name: "Shipping Rate Edit",
        path: "/:shopId/zone/:zoneId/shippingrate/:rateId/edit/:rateType",
        element: ShopAddRatePage,
      },
      {
        name: "Shop-Pickup-Zone",
        path: "/:shopId/shop-pickup-zone",
        element: ShopPickupZoneListPage,
      },
    ],
  },

  // Categories Routes
  {
    name: "Categories",
    path: "/categories",
    icon: faSitemap,
    sub: [
      {
        name: "Categories",
        path: "/",
        icon: faListUl,
        element: CategoryPage,
      },
      {
        name: "Sub Category",
        path: "/level=2",
        icon: faListNumeric,
        element: SecondLevelCategoryPage,
      },
      {
        name: "Child Category",
        path: "/level=3",
        icon: faListNumeric,
        element: ThirdLevelCategoryPage,
      },
      {
        name: "Delete",
        path: "/:shopId/delete",
      },
      {
        name: "Details",
        path: "/:categoryId",
        element: CategoryDetailsPage,
      },
      {
        name: "Products",
        path: "/:categoryId/products",
        element: CategoryProductPage,
      },
    ],
  },

  // Global Products Catalogue Routes
  {
    name: "Product Catalogue",
    path: "/global-product",
    icon: faClone,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faClone,
        element: GlobalProductPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:slug/details",
        element: GlobalProductDetailsPage,
      },
      {
        name: "Add",
        path: "/create",
        element: GlobalProductAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: GlobalProductEditPage,
      },
    ],
  },
  // Inventory & Products Routes
  {
    name: "Inventory & Products",
    path: "/products",
    icon: faShoppingBasket,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faShoppingBasket,
        element: ProductPage,
        isParent: true,
      },
      {
        name: "Bags",
        path: "/bags",
        icon: faCopy,
        element: BagPage,
        isParent: true,
      },
      {
        name: "TP-Update",
        path: "/tp-update",
        icon: faShoppingBasket,
        element: TpUpdateProductPage,
        isParent: true,
      },
      {
        name: "Bag-Create",
        path: "/bag-create",
        element: BagAddPage,
      },
      {
        name: "Create",
        path: "/create",
        element: ProductAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/bag-edit",
        element: BagEditPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: ProductEditPage,
      },
      {
        name: "Details",
        path: "/:slug/details",
        element: ProductDetailsPage,
      },
      {
        name: "Delete",
        path: "/:productId/delete",
      },
      {
        name: "Product Display Update Order",
        path: "/:productId/product/update-display-order-by-productId",
      },
      {
        name: "Requested stock Product",
        path: "/requested-product",
        icon: faHand,
        element: RequestedProductPage,
        isParent: true,
      },
    ],
  },
  
  //Brands Routes
  {
    name: "Brands",
    path: "/brands",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: BrandPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:brandId/details",
        element: BrandDetailsPage,
      },
      {
        name: "Delete",
        path: "/:brandId/delete",
      },
      {
        name: "Update",
        path: "/:brandId/update",
      },
      {
        name: "Brand Update Display Order",
        path: "/:brandId/update-display-order",
      },
      {
        name: "Products",
        path: "/:brandId/products",
        element: BrandProductPage,
      },
    ],
  },

  //Country routes
  {
    name: "Country",
    path: "/country",
    icon: faImages,
    sub: [
      {
        name: "Country",
        path: "/",
        icon: faListNumeric,
        element: CountryPage,
      },
      {
        name: "State",
        path: "/state",
        icon: faListNumeric,
        element: CountryStatePage,
      },
      {
        name: "City",
        path: "/city",
        icon: faListNumeric,
        element: CountryCityPage,
      },
      {
        name: "Area",
        path: "/area",
        icon: faListNumeric,
        element: AreaPage,
      },
      {
        name: "State-bulk-upload",
        path: "/state-bulk-upload",
        element: StateBulkUploadPage,
      },
      {
        name: "City-bulk-upload",
        path: "/city-bulk-upload",
        element: CityBulkUploadPage,
      },
      {
        name: "Area-bulk-upload",
        path: "/area-bulk-upload",
        element: AreaBulkUploadPage,
      },
      {
        name: "Sub-Urb",
        path: "/sub-urb",
        icon: faListNumeric,
        element: SuburbPage,
      },
      {
        name: "Suburb-bulk-upload",
        path: "/suburb-bulk-upload",
        element: SubUrbBulkUploadPage,
      },
    ],
  },
  //Business Routes
  {
    name: "Business",
    path: "/business",
    icon: faCube,
    sub: [
      {
        name: "Business Type",
        path: "/type",
        icon: faListNumeric,
        element: BusinessTypePage,
      },
      {
        name: "Service-List",
        path: "/service-list",
        icon: faListNumeric,
        element: serviceListPage,
      },
      {
        name: "Service Type",
        path: "/service-type",
        icon: faListNumeric,
        element: serviceTypePage,
      },
    ],
  },
  // Banners routes
  {
    name: "Banners",
    path: "/banners",
    icon: faImages,
    element: BannerPage,
  },
  // Marketing & Promotions Routes
  {
    name: "Marketing & Promotions",
    path: "/promotion",
    icon: faGift,
    sub: [
      {
        name: "Campaign",
        path: "/campaign",
        icon: faBullhorn,
        element: CampaignPage,
      },
      {
        name: "Campaign Details",
        path: "/campaign/:campaignId/details",
        element: CampaignDetailsPage,
      },
      {
        name: "Campaign Add",
        path: "/campaign/add",
        element: CampaignAddPage,
      },
      {
        name: "Campaign Edit",
        path: "/campaign/:campaignId/edit",
        element: CampaignAddPage,
      },
      {
        name: "Campaign Products",
        path: "/campaign/:campaignId/products",
        element: CampaignProductPage,
      },
      {
        name: "Campaign Products Assign",
        path: "/campaign/:campaignId/products-assign",
        element: CampaignProductAssignPage,
      },
      {
        name: "Campaign Products Remove",
        path: "/campaign/:campaignId/remove-products",
      },
      {
        name: "popular-search",
        path: "/api/v1/popular-search",
      },
      {
        name: "Campaign Orders",
        path: "/campaign/:campaignId/orders",
        element: CampaignOrderPage,
      },
      {
        name: "Campaign Orders Details",
        path: "/campaign/:campaignId/orders/:orderId/details",
        element: CampaignOrderDetailsPage,
      },
      {
        name: "Campaign Brands",
        path: "/campaign/:campaignId/brands",
        element: CampaignBrandPage,
      },
      {
        name: "Campaign Shops",
        path: "/campaign/:campaignId/shops",
        element: CampaignShopsPage,
      },
      {
        name: "Promo Code",
        path: "/promo-code",
        icon: faTicket,
        element: PromoCodePage,
      },
      {
        name: "Promo Code Users",
        path: "/promo-code/:promoCode/users",
        element: PromoCodeUsesUserPage,
      },
      {
        name: "Groups",
        path: "/groups",
        icon: faTicket,
        element: CampaignGroupsPage,
      },
      {
        name: "Gifts",
        path: "/gifts",
        icon: faGifts,
        element: CampaignPage,
      },
      {
        name: "Gift Card Template category",
        path: "/gift-card-template-category",
        icon: faFilm,
        element: GiftCardTemplateCategoryPage,
      },
      {
        name: "Gift Card Template",
        path: "/gift-card-template",
        icon: faFilm,
        element: GiftCardTemplatePage,
      },
      {
        name: "Advertise",
        path: "/advertise",
        icon: faFilm,
        element: AdvertisePage,
      },
    ],
  },
  // Users/customer Routes
  {
    name: "Customer",
    path: "/customer",
    icon: faUsers,
    element: UserPage,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faUsers,
        element: UserPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:userId/details",
        element: UserDetailsPage,
      },
    ],
  },
  // Merchant Routes
  {
    name: "Merchants",
    path: "/merchants",
    icon: faHandshake,
    sub: [
      {
        name: "All Merchant",
        path: "/",
        icon: faUsers,
        element: MerchantPage,
        // isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: MerchantAddPage,
      },
      {
        name: "Edit",
        path: "/:merchantId/edit",
        element: MerchantAddPage,
      },
      {
        name: "Details",
        path: "/:merchantId/details",
        element: MerchantDetailsPage,
      },
      {
        name: "Shops",
        path: "/:merchantId/shops",
        element: MerchantShopPage,
      },
      {
        name: "Products",
        path: "/:merchantId/products",
        element: MerchantProductPage,
      },

      // Requested Products Routes
      {
        name: "Merchant Requested Product",
        path: "/requested-product",
        icon: faClone,
        element: MerchantRequestedProductPage,
      },
      {
        name: "Requested Product Edit",
        path: "/requested-product/:slug",
        icon: faClone,
        element: MerchantRequestedProductEdit,
      },
      {
        name: "Recruiter",
        path: "/recruiters",
        icon: faUserTie,
        element: MerchantRecruiterPage,
      },
      {
        name: "Type",
        path: "/types",
        icon: faObjectUngroup,
        element: MerchantTypePage,
      },

    ],
  },
  // Neighborhood Routes
  {
    name: "Neighborhood",
    path: "/neighborhood",
    icon: faMapLocationDot,
    sub: [
      {
        name: "Neighborhood All",
        path: "/",
        icon: faLaptop,
        element: NeighborhoodPage,
        isParent: true,
      },
      {
        name: "Neighborhood Add",
        path: "/add",
        element: NeighborhoodAddPage,
      },

      {
        name: "Neighborhood Details",
        path: "/:neighborhoodId/:shopType/details",
        element: NeighborhoodDetailsPage,
      },
      {
        name: "Neighborhood Details All",
        path: "/details",
        element: ViewAllNeighborhoodPage,
      },
    ],
  },
  //Delivery & Shipping Routes
  {
    name: "Delivery & Shipping",
    path: "/delivery-shipping",
    icon: faTruckFast,
    sub: [
      {
        name: "Shipping & Delivery Settings",
        path: "/settings",
        icon: faTruckFast,
        element: DeliveryShippingPage,
        isParent: true,
      },
      {
        name: "Add Manual Shipping Orders",
        path: "/bank-info",
        icon: faCartShopping,
        element: ManualShippingOrdersPage,
      },
      {
        name: "Add Zone",
        path: "/add-zone",
        icon: faCartShopping,
        element: ShippingZoneListPage,
      },
      {
        name: "Delivery-Carrier",
        path: "/delivery-carrier-info",
        icon: faCartShopping,
        element: DeliveryCarrierPage,
      },
      // {
      //   name: "Pickup-Zone",
      //   path: "/pickup-zone",
      //   icon: faCartShopping,
      //   element: PickupZonePage,
      // },
      {
        name: "General Shipping Rates",
        path: "/shipping-rates",
        element: ShippingRatesPage,
      },
      {
        name: "Shipping Profile",
        path: "/shipping-profile",
        element: ShippingProfilePage,
      },
      {
        name: "Processing Time",
        path: "/processing-time",
        element: ProcessingTimePage,
      },
      {
        name: "New Shipping Zone",
        path: "/shipping-zone",
        element: ShippingZonePage,
      },
      {
        name: "Edit",
        path: "/:id/edit",
        element: ShippingZonePage,
      },
      {
        name: "Shipping Place",
        path: "/shipping-place",
        element: ShippingPlacePage,
      },
      {
        name: "Add Package",
        path: "/add-package",
        element: AddPackagePage,
      },
      {
        name: "Add Rate",
        path: "/add-rate",
        element: AddRatePage,
      },
      {
        name: "Drivers",
        path: "/drivers",
        icon: faBiking,
        element: DeliveryHeroPage,
        isParent: true,
      },
      {
        name: "Drivers Details",
        path: "/drivers/:id/details",
        element: DriverDetailsPage,
      },
      {
        name: "Orders",
        path: "/drivers/:id/orders",
        element: DriverOrdersPage,
      },
      {
        name: "Transactions",
        path: "/drivers/:id/transactions",
        element: DriverTransactionPage,
      },
      {
        name: "Cash Collect",
        path: "/drivers/:id/cash-collect",
        element: DriversCashCollectPage,
      },
      {
        name: "Vehicle Information",
        path: "/drivers/:id/vehicle-info",
        element: DriverVehicleInfoPage,
      },
      {
        name: "Unsettle Order",
        path: "/drivers/:id/unsettle-order",
        element: DriverUnsettleOrderPage,
      },

      {
        name: "Location History",
        path: "/drivers/:id/location-history",
        element: DriverLocationHistoryPage,
      },
      {
        name: "Time Slot",
        path: "/time-slot",
        icon: faClock,
        element: TimeSlotPage,
      },
      // {
      //   name: "Vehicle Type",
      //   path: "/vehicle-type",
      //   icon: faCar,
      //   element: VehicleTypePage,
      // },
      // {
      //   name: "Service Zone",
      //   path: "/service-zone",
      //   icon: faMapMarker,
      //   element: ServiceZonePage,
      // },
      {
        name: "Delete",
        path: "/:zoneId/delete",
      },
      // {
      //   name: "Service Location",
      //   path: "/service-location",
      //   icon: faMapLocationDot,
      //   element: ServiceLocationPage,
      // },
      {
        name: "Pathao Store",
        path: "/pathao-store",
        icon: faTruck,
        element: pathaoStorePage,
      },
    ],
  },
  // Delivery Routes
  // {
  //   name: "Delivery",
  //   path: "/delivery",
  //   icon: faTruck,
  //   sub: [
  //     {
  //       name: "Drivers",
  //       path: "/drivers",
  //       icon: faBiking,
  //       element: DeliveryHeroPage,
  //       isParent: true,
  //     },
  //     {
  //       name: "Drivers Details",
  //       path: "/drivers/:id/details",
  //       element: DriverDetailsPage,
  //     },
  //     {
  //       name: "Orders",
  //       path: "/drivers/:id/orders",
  //       element: DriverOrdersPage,
  //     },
  //     {
  //       name: "Transactions",
  //       path: "/drivers/:id/transactions",
  //       element: DriverTransactionPage,
  //     },
  //     {
  //       name: "Cash Collect",
  //       path: "/drivers/:id/cash-collect",
  //       element: DriversCashCollectPage,
  //     },
  //     {
  //       name: "Vehicle Information",
  //       path: "/drivers/:id/vehicle-info",
  //       element: DriverVehicleInfoPage,
  //     },
  //     {
  //       name: "Unsettle Order",
  //       path: "/drivers/:id/unsettle-order",
  //       element: DriverUnsettleOrderPage,
  //     },

  //     {
  //       name: "Location History",
  //       path: "/drivers/:id/location-history",
  //       element: DriverLocationHistoryPage,
  //     },
  //     {
  //       name: "Time Slot",
  //       path: "/time-slot",
  //       icon: faClock,
  //       element: TimeSlotPage,
  //     },
  //     {
  //       name: "Delete",
  //       path: "/:zoneId/delete",
  //     },
  //     {
  //       name: "Pathao Store",
  //       path: "/pathao-store",
  //       icon: faTruck,
  //       element: pathaoStorePage,
  //     },
  //   ],
  // },
  //Keyword Routes
  {
    name: "Keywords",
    path: "/keywords",
    icon: faAlignCenter,
    sub: [
      {
        name: "All Keywords",
        path: "/all",
        icon: faAlignLeft,
        element: KeywordPage,
      },
      {
        name: "Popular",
        path: "/popular",
        icon: faAlignLeft,
        element: PopularKeywordPage,
      },
      {
        name: "Product",
        path: "/product",
        icon: faAlignLeft,
        element: ProductKeywordPage,
      },
    ],
  },
  // Content category Routes
  {
    name: "Contents",
    path: "/contents",
    icon: faPager,
    sub: [
      {
        name: "Pages",
        path: "/pages",
        icon: faDiagramNext,
        element: ContentCategoryPage,
      },

      {
        name: "Page Details",
        path: "/pages/:slug",
        element: ContentCategoryDetailsPage,
      },

      {
        name: "Page Create",
        path: "/pages/create",
        element: ContentCategoryAddPage,
      },
      {
        name: "Page Edit",
        path: "/pages/:slug/edit",
        element: ContentCategoryAddPage,
      },

      {
        name: "Articles",
        path: "/",
        icon: faListUl,
        element: ContentPage,
      },
      {
        name: "Details",
        path: "/:slug",
        element: ContentDetailsPage,
      },

      {
        name: "Create",
        path: "/create",
        element: ContentAddPage,
      },
      {
        name: "Edit",
        path: "/:slug/edit",
        element: ContentAddPage,
      },
    ],
  },

    // Content category Routes
    {
      name: "Vehicle",
      path: "/vehicles",
      icon: faPager,
      sub: [
        {
          name: "Vehicle",
          path: "/vehicle-list",
          icon: faDiagramNext,
          element: VehicleListPage,
        },
        {
          name: "Vehicle Details",
          path: "/:vehicleId/details",
          element: VehicleDetailsPage,
          //element: CampaignDetailsPage,
        },
        {
          name: "Vehicle Add",
          path: "/vehicle-add",
          element: VehicleAddPage,
          //element: CampaignAddPage,
        },
        {
          name: "Vehicle Edit",
          path: "/:vehicleId/edit",
          element: VehicleAddPage,
          //element: CampaignAddPage,
        },
        {
          name: "Vehicle Type",
          path: "/type",
          icon: faDiagramNext,
          element: VehicleTypeListPage,
        },
        {
          name: "Fuel Type",
          path: "/fuelType",
          icon: faDiagramNext,
          element: FuelTypeListPage,
        },
        {
          name: "Fuel Grade",
          path: "/fuel-grade",
          icon: faDiagramNext,
          element: FuelGradeListPage,
        },
        {
          name: "BRTA",
          path: "/brta",
          icon: faDiagramNext,
          element: BrtaListPage,
        },
        {
          name: "Vehicle-Feature",
          path: "/vehicle-feature",
          icon: faDiagramNext,
          element: VehicleFeatureListPage,
        },
        // {
        //   name: "Vehicle Brand",
        //   path: "/brand",
        //   icon: faDiagramNext,
        //   element: VehicleBrandListPage,
        // },
        {
          name: "Vehicle Brand Model",
          path: "/brand-model",
          icon: faDiagramNext,
          element: VehicleBrandModelListPage,
        },
      ],
    },

  // Feedbacks Routes
  {
    name: "Feedbacks",
    path: "/feedbacks",
    icon: faComments,
    sub: [
      {
        name: "Feedback List",
        path: "/all",
        icon: faComment,
        element: FeedbackPage,
      },
      {
        name: "Feedback Type",
        path: "/type",
        icon: faAlignLeft,
        element: FeedbackTypePage,
      },
      {
        name: "Details",
        path: "/:feedbackId",
        icon: faAlignLeft,
        element: FeedbackDetailsPage,
      },
    ],
  },
  // Finance & Payment Routes
  {
    name: "Finance & Payment",
    path: "/finance",
    icon: faCopy,
    sub: [
      {
        name: "My Transactions",
        path: "/transactions",
        icon: faAlignLeft,
        element: MyTransactionPage,
      },
      {
        name: "Settlement Request",
        path: "/my-settlement",
        icon: faAlignLeft,
        element: MySettlementPage,
      },
      {
        name: "Cash Collection",
        path: "/",
        icon: faAlignLeft,
        element: CompanyBlanceHistoryPage,
      },
      {
        name: "Deposit",
        path: "/deposit",
        icon: faAlignLeft,
        element: CompanyBlanceDepositPage,
      },
      {
        name: "VR History",
        path: "/vr-history",
        icon: faAlignCenter,
        element: VRHistoryPage,
      },
      {
        name: "Order Vat-Tax",
        path: "/order-vat-tax",
        icon: faAlignCenter,
        element: OrderVatTaxPage,
      },
      {
        name: "Delivery Vat-Tax",
        path: "/delivery-vat-tax-report",
        icon: faAlignCenter,
        element: VatTaxDeliveryReportPage,
      },
      {
        name: "TOPUP History",
        path: "/topup-history",
        icon: faAlignLeft,
        element: CompanyTopUpHistoryPage,
      },
      {
        name: "TopUp Details",
        path: "/:companyId/details",
        element: CompanyTopUpDetailsPage,
      },
      {
        name: "Company Balance History",
        path: "/company-balance-history",
        icon: faAlignLeft,
        element: CompanyBlanceHistoryPage,
      },
    ],
  },

  // Reports Routes
  {
    name: "Reports",
    path: "/reports",
    icon: faList,
    sub: [
      {
        name: "Delivery Report",
        path: "/delivery-report",
        icon: faAlignCenter,
        element: DeliveryReportPage,
      },
      {
        name: "Sales Report",
        path: "/sales-report",
        icon: faAlignCenter,
        element: SalesReportPage,
      },
      {
        name: "Revenue Report",
        path: "/revenue-report",
        icon: faAlignCenter,
        element: RevenueReportPage,
      },
      
    ],
  },
  // ShoppingMall Routes
  {
    name: "Shopping-Mall",
    path: "/shopping-mall",
    icon: faBuilding,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: ShoppingMallPage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:mallId/details",
        element: ShoppingMallDetailsPage,
      },
    ],
  },
  // Corporate Routes
  {
    name: "Corporate",
    path: "/corporates",
    icon: faLaptop,
    sub: [
      {
        name: "Corporate List",
        path: "/",
        icon: faHandsHelping,
        element: CorporatePage,
        isParent: true,
      },
      {
        name: "Details",
        path: "/:corporateId/details",
        element: CorporateDetailsPage,
      },
      {
        name: "Employee-Group",
        path: "/:corporateId/employee-group",
        element: CorporateEmployeeGroupPage,
      },
      {
        name: "Employees",
        path: "/:corporateId/employees",
        element: CorporateEmployeepage,
      },
      {
        name: "Topup-History",
        path: "/:corporateId/topup",
        element: CorporateTopupHistoryPage,
      },
      {
        name: "Topup-Details",
        path: "/:corporateId/topupdetails",
        element: CorporateTopupDetailsPage,
      },
      {
        name: "Blance-History",
        path: "/:corporateId/blance",
        element: CorporateBlanceHistoryPage,
      },
      {
        name: "Topup-Request",
        path: "/:corporateId/topup-request",
        element: CorporateTopUpRequestPage,
      },
      {
        name: "Approved-Request",
        path: "/:corporateId/approved",
        element: CorporateTopupRequestApprovedPage,
      },
      {
        name: "Approved-Request-Details",
        path: "/:corporateId/approved/:topupId/details",
        element: CorporateTopupRequestApprovedDetailsPage,
      },

    ],
  },
  // Tags Routes
  {
    name: "Tags",
    path: "/tags",
    icon: faImages,
    element: TagPage,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faUsers,
        element: TagPage,
        isParent: true,
      },
      {
        name: "Delete",
        path: "/:tagId/delete",
      },
    ],
  },
  //Payment Methods routes
  {
    name: "Payment Methods",
    path: "/payment-methods",
    icon: faCreditCard,
    element: PaymentMethodPage,
  },

  // Notifications Routes
  {
    name: "Notifications",
    path: "/notifications",
    icon: faBell,
    sub: [
      {
        name: "SMS",
        path: "/sms",
        icon: faComments,
        element: SMSNotificationPage,
      },
      {
        name: "Email",
        path: "/email",
        icon: faEnvelopeOpen,
        element: EmailNotificationPage,
      },
      {
        name: "Push Notifications",
        path: "/push",
        icon: faBell,
        element: PushNotificationPage,
      },
    ],
  },

  // Configuration Routes
  {
    name: "Configurations",
    path: "/configurations",
    icon: faSliders,
    sub: [
      {
        name: "Point Configuration",
        path: "/referral",
        icon: faList,
        element: PointConfgPage,
      },
      {
        name: "Revenue Distribution Config",
        path: "/revenue-distribution-config",
        icon: faGears,
        element: RevenueDistributionConfgPage,
      },
      {
        name: "Default Delivery Config",
        path: "/default-delivery-config",
        icon: faGears,
        element: DefaultDeliveryConfgPage,
      },
      {
        name: "Default Delivery Config Create",
        path: "/default-delivery-config-create",
        element: DefaultDeliveryConfgCreatePage,
      },
      {
        name: "Membership Level",
        path: "/membership-level",
        icon: faObjectUngroup,
        element: MembershipLevelPage,
      },
    ],
  },

  // Administration Routes
  {
    name: "Administration",
    path: "/administration",
    icon: faUserShield,
    sub: [
      {
        name: "User Management",
        icon: faUser,
        path: "/admins",
        element: UserManagementPage,
      },
      {
        name: "User Groups",
        icon: faUsers,
        path: "/groups",
        element: ServiceGroupPage,
      },
      {
        name: "User Services",
        icon: faList,
        path: "/services",
        element: UserServicePage,
      },
    ],
  },
    // Merchant Administration Routes
    {
      name: "Merchant Administration",
      path: "/merchant-administration",
      icon: faUserShield,
      sub: [
        {
          name: "Merchant Management",
          icon: faUser,
          path: "/merchant-users",
          element: MerchantUserManagementPage,
        },
        {
          name: "Membership User Group",
          icon: faUsers,
          path: "/merchant-user-groups",
          element: MerchantUserGroupPage,
        },
        {
          name: "Merchant Group",
          icon: faUsers,
          path: "/merchant-groups",
          element: MerchantServiceGroupPage,
        },
        {
          name: "Merchant Services",
          icon: faList,
          path: "/merchant-services",
          element: MerchantServicePage,
        },
      ],
    },
  // App Routes add
  {
    name: "App",
    path: "/app",
    icon: faComments,
    sub: [
      {
        name: "Jachai",
        path: "/jachai",
        icon: faComment,
        element: JachaiAppVersionPage,
      },
      {
        name: "Merchant",
        path: "/merchant",
        icon: faAlignLeft,
        element: MerchantAppVersionPage,
      },
    ],
  },
  // Short URL routes
  {
    name: "Short URL",
    path: "/short-url",
    icon: faImages,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faImages,
        element: ShortURLPage,
        isParent: true,
      },
      {
        name: "Delete",
        path: "/:id/delete",
      },
    ],
  },
  // Url Checker routes
  {
    name: "Url Checker",
    path: "/url-checker",
    icon: faLink,
    element: UrlCheckerPage,
  },

  //Programs Routes
  {
    name: "Menu",
    path: "/main-menu",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: MainMenuPage,
        isParent: true,
      },
    ],
  },
  //Programs Routes
  {
    name: "Programs",
    path: "/programs",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: ProgramPage,
        isParent: true,
      },
    ],
  },
  //Discipline Routes
  {
    name: "Disciplines",
    path: "/disciplines",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: DisciplinePage,
        isParent: true,
      },
    ],
  },

  //Curriculum Routes
  {
    name: "Curriculum",
    path: "/curriculum",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: CurriculumPage,
        isParent: true,
      },
    ],
  },
  //Course Routes
  {
    name: "Courses",
    path: "/course",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: CoursePage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: CourseAddPage,
      },
      {
        name: "Edit",
        path: "/:courseId/edit",
        element: CourseAddPage,
      },
    ],
  },
  //Course-Syllabus Routes
  {
    name: "Course-Syllabus",
    path: "/course-syllabus",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: CourseSyllabusPage,
        isParent: true,
      },
    ],
  },
  //Course-Quiz Routes
  {
    name: "Course-Quiz",
    path: "/course-quiz",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: CourseQuizPage,
        isParent: true,
      },
    ],
  },
  //Content Routes
  {
    name: "E-Learning Content",
    path: "/e-content",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: EContentPage,
        isParent: true,
      },
    ],
  },
  //chapter Routes
  {
    name: "Chapter",
    path: "/chapter",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: ChapterPage,
        isParent: true,
      },
    ],
  },

  //Teacher Routes
  {
    name: "Teacher",
    path: "/teacher",
    icon: faCube,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faCube,
        element: TeacherPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: TeacherAddPage,
      },
      {
        name: "Edit",
        path: "/:teacherId/edit",
        element: TeacherAddPage,
      },
    ],
  },

  // AddOns Routes
  // {
  //   name: "Add Ons",
  //   path: "/addons",
  //   icon: faImages,
  //   element: AddOnsPage,
  // },

  // Booking Routes
  {
    name: "Book Upload",
    path: "/book",
    icon: faImages,
    element: BookPage,
    sub: [
      {
        name: "All",
        path: "/",
        icon: faUsers,
        element: BookPage,
        isParent: true,
      },
      {
        name: "Create",
        path: "/create",
        element: BookAddPage,
      },
      {
        name: "Edit",
        path: "/:id/edit",
        element: BookEditPage,
      },
      {
        name: "Delete",
        path: "/:bookId/delete",
      },
    ],
  },
  // File Uploader routes
  {
    name: "File Uploader",
    path: "/file-uploader",
    icon: faUpload,
    element: FileUploaderPage,
  },
];

export const getAllServicesList = () => {
  return adminServices?.flatMap((service) => {
    if (service.sub && service.sub.length) {
      return service.sub.map((sub) => `${service.name} ${sub.name}`);
    } else {
      return service.name;
    }
  });
};

export const getServicePaths = (path: string | undefined) => {
  if (path) {
    const parentService = adminServices?.find(
      (service) => service.path === path
    );
    if (parentService) {
      return parentService?.path || "";
    } else {
      const parentService = adminServices?.find((service) =>
        service.sub?.find((sub) => `${service.path}${sub.path}` === path)
      );
      return parentService?.path || "";
    }
  }
  return "";
};
