import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { AutoComplete, Avatar, Button, DatePicker, Drawer, Empty, Form, Input, Select, Spin } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleRecruiter from "./Details";
import AddRecruiter from "./AddRecruiter";
import { Loader, Pagination, AddBtn } from "../../common";
import moment from "moment";
const { RangePicker } = DatePicker;
const MerchantRecruiterList = (): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedRecruiter, setSelectedRecruiter] = useState(undefined);
  const [form] = Form.useForm();
  const [selectedRecruiterForEdit, setSelectedRecruiterForEdit] =
    useState(undefined);
    const [mobileNumber, setMobileNumber] = useState<any>();
    const [range, setRange] = useState<any>({
      from: null,
      to: null,
    });
  const [recruiterData, setRecruiterData] = useState<any>({
    loading: false,
    data: null,
  });

  const getRecruiters = useCallback(async () => {
    setRecruiterData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/recruiter?type=${type}` +
          (status ? `&deleted=${status}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRecruiterData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setRecruiterData({ loading: false, data: [] });
        console.error("Recruiter: Error", err);
      });
  }, []);

  //
  // const onDelete = async (id: any, val: any) => {
  //   if (id) {
  //     await fetch(`${process.env.REACT_APP_RIDER_API}/recruiter`, {
  //       method: "PUT",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: id,
  //         deleted: !val,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.statusCode === 200) {
  //           responseNotification("Status Updated Successfully", "success");
  //           getRecruiters();
  //         } else if (res.status === 500) {
  //           responseNotification("Internal server error", "error");
  //         } else {
  //           responseNotification(res.message || "something wrong", "warning");
  //         }
  //       })
  //       .catch((err) => {
  //         responseNotification(`${"Internal server error"} ${err}`, "error");
  //         console.error("err", err);
  //       });
  //   }
  // };

  const reseAllFieldData = () => {
    setStatus("");
    setMobileNumber("");
    setRange({
      from: null,
      to: null,
    });
    form.resetFields();
    window.location.reload();
  };
  useEffect(() => {
    getRecruiters();
  }, [getRecruiters]);

  const onClose = () => {
    getRecruiters();
    setVisible(undefined);
    setSelectedRecruiter(undefined);
    setSelectedRecruiterForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Recruiter List"
        subTitle={`${
          recruiterData?.data?.totalElements ||
          recruiterData?.data?.recruiters?.length ||
          0
        } Recruiter(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
       {showSearch && (
        <div className={styles.searchBox}>
          <Form form={form} layout="inline" className={styles.formInline}>
            <Form.Item name="search" initialValue={mobileNumber}>
              <AutoComplete
                style={{ width: 300 }}
                onSelect={(val: { toString: () => any }) =>
                  setMobileNumber(val?.toString())
                }
                defaultActiveFirstOption={false}
              
              >
                <Input.Search
                  style={{ minWidth: 100, width: "calc(100% - 45px)" }}
                  size="large"
                  placeholder="Name or Phone Number"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  pattern={`[0-9]`}
                  maxLength={11}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item initialValue={status}>
              <Select
                placeholder="User Status"
                onChange={(val) => setStatus(val)}
                value={status}
                style={{ minWidth: 250 }}
              >
                <Option value={""}>ALL</Option>
                <Option value={"REGULAR"}>REGULAR</Option>
                <Option value={"ACTIVE"}>ACTIVE</Option>
              </Select>
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {recruiterData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Agency Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {recruiterData?.data?.recruiters?.length ? (
                      recruiterData?.data?.recruiters?.map(
                        (recruiter: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            // onClick={() => setSelectedRecruiter(recruiter)}
                          >
                            <td className="flex items-center gap-2">
                              <Avatar
                                size={45}
                                src={recruiter?.profilePicture}
                                shape="square"
                                className="border"
                              />
                              <span className="font-medium text-gray-500">
                                {recruiter?.name}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {recruiter?.mobileNumber?.replace("+88", " ")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500">
                                {recruiter?.agencyName}
                              </span>
                            </td>
                            {/* <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={!recruiter?.deleted}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onDelete(recruiter?.id, val);
                                }}
                              />
                            </td>

                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() =>
                                  setSelectedRecruiterForEdit(recruiter)
                                }
                              >
                                <EditOutlined />
                              </Button>
                            </td> */}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...recruiterData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Recruiter"
            : selectedRecruiterForEdit
            ? "Edit Recruiter"
            : "Recruiter Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedRecruiter || selectedRecruiterForEdit}
        width={450}
      >
        {visible || selectedRecruiterForEdit ? (
          <AddRecruiter
            onCloseMethod={onClose}
            visibleData={selectedRecruiterForEdit}
          />
        ) : (
          <SingleRecruiter SingleRecruiter={selectedRecruiter} />
        )}
      </Drawer>
    </>
  );
};

export default MerchantRecruiterList;
