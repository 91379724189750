import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import Img from "../../images/empty.jpeg";
import styles from "../../styles/tailwind/List.module.css";
import BreadCrumb from "../Layouts/Breadcrumb";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  List,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { responseNotification } from "../../utils/notify";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCableCar,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import SubmitResetBtn from "../common/SubmitBtn";
import { debounce } from "lodash";
import axios from "axios";

const ShippingZone = ({ visibleData, onCloseMethod }: any) => {
  const [form] = Form.useForm();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [getCountryId, setGetCountryId] = useState<any>("");
  const [businessCountryValue, setBusinessCountryValue] = useState<any>("");
  const [businessStateId, setBusinessStateId] = useState<any>("");

  const [countryState, setCountryState] = useState<any>({
    loading: false,
    data: null,
  });
  const [countryId, setCountryId] = useState<any>();
  const route = useParams();
  const [stateID, setStateID] = useState<any>();
  const [businessCityId, setBusinessCityId] = useState<any>([]);
  console.log("businessCityId ::", businessCityId);

  const [companyOptions, setCompanyOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [deliveryStateOptions, setDeliveryStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [zoneData, setZoneData] = useState<any>({
    loading: false,
    data: null,
  });
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      countryId: getCountryId,
      stateId: businessStateId,
      cityIds: businessCityId,
    };

    if (zoneData.data) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: zoneData?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/delivery-zone/add-zone-v2`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getCountryOptions = useCallback(async (countryName?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (countryName ? `&key=${countryName}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (stateName?: string) => {
      setStateOptions({ loading: true, list: [] });
      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${getCountryId}&page=0&limit=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCountryState({ loading: false, data: res.data });
      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [getCountryId]
  );

  const getCityOptions = useCallback(
    async (cityName?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?countryId=${getCountryId}&stateId=${businessStateId}&page=0&limit=20`
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [getCountryId, businessStateId]
  );

  const fetchZoneDetails = useCallback((id: any) => {
    if (id) {
      try {
        setZoneData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/delivery-zone/details?id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setZoneData({
                loading: false,
                data: data?.data?.deliveryZone,
              });
            } else {
              setZoneData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setZoneData({ loading: false, data: null });
          });
      } catch (error) {
        setZoneData({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchZoneDetails((route as any)?.id);
  }, [fetchZoneDetails]);

  useEffect(() => {
    if (visibleData) {
      setZoneData(visibleData);
    }
  }, [visibleData]);
  useEffect(() => {
    if (zoneData?.data) {
      setGetCountryId(zoneData?.data?.countryId);
      setBusinessStateId(zoneData?.data?.stateId);
      setBusinessCityId(zoneData?.data?.cityIds);
      form.resetFields(Object.keys(zoneData?.data));
        form.resetFields();
    }
  }, [zoneData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "city") getCityOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getCountryOptions, getStateOptions, getCityOptions, type]);
  useEffect(() => {
    if (getCountryId?.length > 0) {
      getStateOptions(getCountryId);
    }
  }, [getCountryId]);

  useEffect(() => {
    if (getCountryId?.length > 0 && businessStateId?.length > 0) {
      getCityOptions(businessStateId);
    }
  }, [getCountryId, businessStateId]);

  useEffect(() => {
    getCountryOptions();
  }, [getCountryOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        form={form} // like ref
        onFinish={onSubmit}
        layout="horizontal"
        initialValues={{
          ...zoneData,
          name: zoneData?.name,
        }}
      >
        <Card className="mx-8 my-4 rounded-xl">
          <h3 className="mb-2 text-2xl leading-tight text-primary">
            Create new delivery zone
          </h3>
          <div className="">
            <Form.Item
              hasFeedback
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
              name="name"
            >
              <Input id="name" type="text" placeholder="Enter Name" />
            </Form.Item>
          </div>
          <div className="">
            <Form.Item
              hasFeedback
              label={`Country/Region`}
              rules={[
                {
                  required: !visibleData,
                  message: "Region is required!",
                },
              ]}
              name="businessCountry"
            >
              <Select
                allowClear
                showSearch
                placeholder="Select Country"
                optionFilterProp="children"
                defaultValue={getCountryId}
                onChange={(val) => setGetCountryId(val)}
                onSearch={(val) => {
                  handleSearch?.(val, "country");
                }}
                filterOption={() => {
                  return true;
                }}
                options={countryOptions?.list}
              ></Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: !visibleData,
                  message: "state is Required!",
                },
              ]}
            >
              <Select
                placeholder="Select state"
                defaultValue={businessStateId}
                onChange={(val) => setBusinessStateId(val)}
              >
                {countryState?.data?.states?.length ? (
                  countryState?.data?.states?.map((state: any, index: any) => (
                    <Option value={state?.id}>{state?.name}</Option>
                  ))
                ) : (
                  <Empty />
                )}
              </Select>
            </Form.Item>
          </div>

          <div className="float-left w-full mt-8">
            <Form.Item
              hasFeedback
              label={`City`}
              rules={[
                {
                  required: !visibleData,
                  message: "City is required!",
                },
              ]}
              name="businessCity"
            >
              <Select
                allowClear
                showSearch
                placeholder="Select City"
                optionFilterProp="children"
                defaultValue={businessCityId}
                onChange={(val) => setBusinessCityId(val)}
                onSearch={(val) => {
                  handleSearch?.(val, "city");
                }}
                filterOption={() => {
                  return true;
                }}
                mode="multiple"
                options={cityOptions?.list}
              ></Select>
            </Form.Item>
          </div>
        </Card>

        <div className="w-full mx-8 py-0">
          <div className="float-left w-11/12 text-right">
            <SubmitResetBtn
              onClickReset={() => {
                form?.resetFields();
              }}
              disabled={loading || !!error}
              loading={loading}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ShippingZone;
