import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { AddBtn, Loader, LoaderFull, PaginationThree } from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import { Avatar, Button, Empty, Form, Switch, Tag } from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";

const ShopPackagesList = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [packageData, setPackageData] = useState<any>({
    loading: false,
    data: null,
  });

  const getShops = useCallback(async () => {
    setPackageData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/package/my-packages?shopId=${(route as any)?.shopId}` +
          (page
            ? `&page=${
                page == packageData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPackageData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPackageData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [limit, page]);
  useEffect(() => {
    getShops();
  }, [getShops]);
  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Package List"
        subTitle="Package List"
        extra={<></>}
      >
        <>
          <BreadCrumb
            title="Package List"
            subTitle={`${
              packageData?.data?.totalElements ||
              packageData?.data?.deliveryPackages?.length ||
              0
            } Package(s)`}
            extra={[
              <AddBtn
                onClick={() => {
                  navigate(`/shops/${(route as any)?.shopId}/package-add`);
                }}
              />,
            ]}
          />
        </>

        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {confirmLoading && <LoaderFull />}
                    {packageData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Shop</th>
                            <th scope="col">Move TO Top</th>
                            <th scope="col">Public/Private</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col">Created Info</th>
                            <th scope="col">Updated Info</th>
                          </tr>
                        </thead>

                        <tbody>
                          {packageData?.data?.deliveryPackages?.length ? (
                            packageData?.data?.deliveryPackages?.map(
                              (shop: any, index: any) => (
                                <tr
                                  className="border-t hover:bg-gray-100 cursor-pointer"
                                  key={index}
                                >
                                  <td
                                    onClick={() =>
                                      navigate(`/shops/${shop?.id}/details`)
                                    }
                                    className="flex gap-2 items-center"
                                  >
                                    <Avatar
                                      size={45}
                                      src={shop?.logo}
                                      className="border"
                                    />
                                    <span className="font-medium text-gray-500 ml-2 flex flex-col">
                                      {shop?.name}
                                      <span>
                                        {type == "JC_FOOD" && (
                                          <div className="flex">
                                            <span
                                              className={classNames(
                                                `text-xs font-light pl-1 pr-2 py-0.5 leading-3 rounded-full border col-auto flex items-center gap-1`,
                                                shop?.status == "Close"
                                                  ? `bg-red-100 text-red-600`
                                                  : shop?.status == "Busy"
                                                  ? `bg-yellow-200 text-yellow-600`
                                                  : `bg-green-100 text-green-600`
                                              )}
                                            >
                                              <ClockCircleOutlined />
                                              <small>
                                                {shop?.status?.toUpperCase()}
                                              </small>
                                            </span>
                                          </div>
                                        )}
                                      </span>
                                    </span>
                                  </td>

                                  <td>
                                    <Button shape="round" type="primary" danger>
                                      <ToTopOutlined />
                                    </Button>
                                  </td>
                                  <td>
                                    <Tag>
                                      {shop?.isPublic === true
                                        ? "Public"
                                        : "Private"}
                                    </Tag>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              <PaginationThree
                {...packageData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPackagesList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPackagesList;
