import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  SubmitResetBtn,
} from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Tag,
} from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { error } from "console";
import { responseNotification } from "../../../utils/notify";

const ShopPackagesAdd = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [size, setSize] = useState<SizeType>("large");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [packingUnitType, setPackingUnitType] = useState<any>();
  const [whoPrepsUnit, setWhoPrepsUnit] = useState<any>(undefined);
  const [whoLabelUnits, setWhoLabelUnits] = useState<any>(undefined);
  const [defaultSellerPackage, setDefaultSellerPackage] = useState(false);
  const [packingType, setPackingType] = useState<any>();
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      packageUnitType: {
        caseUnits: packingUnitType === "caseUnits" ? true : false,
        groupUnits: packingUnitType === "groupUnits" ? true : false,
        individualUnits: packingUnitType === "individualUnits" ? true : false,
      },
      packageType: {
        box: packingType === "box" ? true : false,
        envelope: packingType === "envelope" ? true : false,
        softPackageOrSatchel:
          packingType === "softPackageOrSatchel" ? true : false,
      },
      isCustomPackage: true,
      shopId: (route as any)?.shopId,
      whoLabelsUnits: whoLabelUnits,
      whoPrepsUnits: whoPrepsUnit,
      packageSizeAndWeight: {
        height: data.height,
        length: data.length,
        totalBoxWeight: data.weight,
        unitPerBox: data.unitPerBox,
        unitType: data.unit,
        width: data.width,
      },
      isMakeThisTheDefaultSellerPackage: defaultSellerPackage,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/package/add-package`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Package Create Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Brand List"
        subTitle="BrandList"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <div className="content-body">
                      <>
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          form={form} // like ref
                          onFinish={onSubmit}
                          layout="horizontal"
                        >
                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="float-left w-full">
                              <div>
                                <Form.Item
                                  hasFeedback
                                  label="Package name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Package name is Required!",
                                    },
                                  ]}
                                  name="name"
                                >
                                  <Input
                                    id="name"
                                    type="text"
                                    placeholder="Enter Package name"
                                  />
                                </Form.Item>
                              </div>
                              <div className="float-left w-full">
                                <Form.Item
                                  hasFeedback
                                  label="Packing Unit type"
                                  name="PackingUnitType"
                                  initialValue={packingUnitType}
                                >
                                  <Radio.Group
                                    onChange={(val) =>
                                      setPackingUnitType(
                                        (val as any).target.value
                                      )
                                    }
                                    value="packingUnitType"
                                    name="packingUnitType"
                                    className="w-full"
                                  >
                                    <Radio
                                      value="individualUnits"
                                      name="individualUnits"
                                      className="float-left w-1/4"
                                    >
                                      Individual Units
                                    </Radio>

                                    <Radio
                                      value="caseUnits"
                                      className="float-left w-1/4"
                                      name="caseUnits"
                                    >
                                      Case Units
                                    </Radio>

                                    <Radio
                                      name="groupUnits"
                                      value="groupUnits"
                                      className="float-left w-1/4"
                                    >
                                      Group Units
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                              <div className="float-left w-full">
                                <Form.Item
                                  hasFeedback
                                  label="Package type"
                                  name="packageType"
                                  initialValue={packingType}
                                >
                                  <Radio.Group
                                    onChange={(val) =>
                                      setPackingType((val as any).target.value)
                                    }
                                    value={packingType}
                                    name="packingType"
                                    className="w-full"
                                  >
                                    <Radio
                                      value="box"
                                      name="box"
                                      className="float-left w-1/4"
                                    >
                                      Box
                                    </Radio>

                                    <Radio
                                      value="envelope"
                                      className="float-left w-1/4"
                                      name="envelope"
                                    >
                                      Envelope
                                    </Radio>

                                    <Radio
                                      name="softPackageOrSatchel"
                                      value="softPackageOrSatchel"
                                      className="float-left w-1/4"
                                    >
                                      Soft Package or Satchel
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-left w-full">
                              <Card
                                title="Package size & weight"
                                className="my-4 rounded-xl"
                              >
                                <div className="float-left w-[20%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Unit per Box"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Unit per Box is Required!",
                                      },
                                    ]}
                                    name="unitPerBox"
                                  >
                                    <Input
                                      id="unitPerBox"
                                      type="text"
                                      placeholder="Unit per Box"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Length"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Length is Required!",
                                      },
                                    ]}
                                    name="length"
                                  >
                                    <Input
                                      id="length"
                                      type="text"
                                      placeholder="Length"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Width"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Width is Required!",
                                      },
                                    ]}
                                    name="width"
                                  >
                                    <Input
                                      id="width"
                                      type="text"
                                      placeholder="Width"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Height"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Height is Required!",
                                      },
                                    ]}
                                    name="height"
                                  >
                                    <Input
                                      id="height"
                                      type="text"
                                      placeholder="Height"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Unit"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Unit is Required!",
                                      },
                                    ]}
                                    name="unit"
                                  >
                                    <Input
                                      id="unit"
                                      type="text"
                                      placeholder="Type your Shipping profile name"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Weight"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Weight is Required!",
                                      },
                                    ]}
                                    name="weight"
                                  >
                                    <Input
                                      id="name"
                                      type="text"
                                      placeholder="Weight"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[10%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Unit"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Unit is Required!",
                                      },
                                    ]}
                                    name="unit"
                                  >
                                    <Input
                                      id="unit"
                                      type="text"
                                      placeholder="Unit"
                                    />
                                  </Form.Item>
                                </div>
                              </Card>
                            </div>
                            <div className="float-left w-full">
                              <div className="float-left w-1/2 p-4">
                                <Form.Item
                                  name="Who preps units"
                                  label="Who preps units"
                                  initialValue={
                                    whoPrepsUnit == "SELLER"
                                      ? "SELLER"
                                      : whoPrepsUnit == "DELIVERYMAN"
                                      ? "DELIVERYMAN"
                                      : "NONE"
                                  }
                                >
                                  <Select
                                    showSearch
                                    placeholder="Status"
                                    onChange={(val) =>
                                      setWhoPrepsUnit(val as string)
                                    }
                                  >
                                    <Select.Option value={undefined}>
                                      NONE
                                    </Select.Option>
                                    <Select.Option
                                      value={"SELLER"}
                                      title="SELLER"
                                    >
                                      SELLER
                                    </Select.Option>
                                    <Select.Option
                                      value={"DELIVERYMAN"}
                                      title="DELIVERYMAN"
                                    >
                                      DELIVERYMAN
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="float-left w-1/2 p-4">
                                <Form.Item
                                  name="Who Labels units"
                                  label="Who Labels units"
                                  initialValue={
                                    whoLabelUnits == "SELLER"
                                      ? "SELLER"
                                      : whoLabelUnits == "DELIVERYMAN"
                                      ? "DELIVERYMAN"
                                      : "NONE"
                                  }
                                >
                                  <Select
                                    showSearch
                                    placeholder="Status"
                                    onChange={(val) =>
                                      setWhoLabelUnits(val as string)
                                    }
                                  >
                                    <Select.Option value={undefined}>
                                      NONE
                                    </Select.Option>
                                    <Select.Option
                                      value={"SELLER"}
                                      title="SELLER"
                                    >
                                      SELLER
                                    </Select.Option>
                                    <Select.Option
                                      value={"DELIVERYMAN"}
                                      title="DELIVERYMAN"
                                    >
                                      DELIVERYMAN
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-left w-full">
                              <Checkbox
                                checked={defaultSellerPackage}
                                onChange={(e) =>
                                  setDefaultSellerPackage(e.target.checked)
                                }
                              >
                                Make this the default seller package
                              </Checkbox>
                            </div>
                            <div className="float-left w-full">
                              <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                                Used to calculate shipping rates at checkout.
                                Also pre-selected when you buy Jachai Shipping
                                labels if a suggested package is not available.
                              </p>
                            </div>

                            <div className="buttons-container">
                            <Button
                              disabled={loading }
                              loading={(loading ? "loading" : undefined) as any}
                              type="primary"
                              htmlType="submit"
                              className="add-submit-btn text-center mr-2"
                            >
                              Submit
                            </Button>

                            <Button
                              htmlType="button"
                              onClick={() => {
                                form?.resetFields();
                              }}
                              className="reset-submit-btn text-center mr-2"
                            >
                              Reset
                            </Button>
                          </div>
                          </Card>
                          
                        </Form>
                      </>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPackagesAdd.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPackagesAdd;
