import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { Button, Card, Divider, Form, Input, Radio, Select, Space } from "antd";
import { CloseOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import styles from "../../styles/tailwind/List.module.css";
import { responseNotification } from "../../utils/notify";
import { jcDayArray } from "../../utils";
import { SubmitResetBtn } from "../common";
import { debounce } from "lodash";
import { SizeType } from "antd/es/config-provider/SizeContext";
const format = "HH:mm:ss";

const ShopAddRateList = (): ReactElement => {
  const route = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const shopId = (route as any)?.shopId;
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [transitType, setTransitType] = useState("PRIORITY_DELIVERY");
  const [shippingRateType, setShippingRateType] =
    useState<string>("setUpYourOwnRates");
  const [conditionType, setConditionType] = useState<any>();
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [conditionChecked, setConditionChecked] = useState(false);
  const [dimensionUnit, setDimensionUnit] = useState<any>("cm");
  const [applyShippingHandlingFee, setApplyShippingHandlingFee] =
    useState(false);
  const [othersCharge, setOthersCharge] = useState(false);
  const [businessDay, setBusinessDay] = useState();
  const [deliveryCarrierId, setDeliveryCarrierId] = useState<any>();
  const [carrierOptions, setCarrierOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [currencyOptions, setCurrencyOptions] = useState<any>({
    list: [],
    loading: false,
  });

  console.log("brandsOptions =====", currencyOptions);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      deliveryCarrierId: deliveryCarrierId,
      id: (route as any)?.zoneId,
      isCarrierRate:
        shippingRateType === "useCarrierOrAppToCalculateRates" ? true : false,
      isOwnShippingRate:
        shippingRateType === "setUpYourOwnRates" ? true : false,
      othersCharge: {
        importCharge: {
          flat: parseInt(data.importChargeFlat),
          maxLimit: parseInt(data.importChargeMaxLimit),
          percentage: parseInt(data.importChargePercentange),
        },
        importTax: {
          flat: parseInt(data.importTaxFlat),
          maxLimit: parseInt(data.importTaxMaxLimit),
          percentage: parseInt(data.importTaxPercentange),
        },
        importVat: {
          flat: parseInt(data.importVatFlat),
          maxLimit: parseInt(data.importVatMaxLimit),
          percentage: parseInt(data.importVatPercentange),
        },
      },
      shippingRate: {
        applyShippingHandlingFee: applyShippingHandlingFee,
        basedOnDistances: data?.basedOnDistanceList?.map(
          (distanceList: any, distanceListId: string) => ({
            charge: parseInt(distanceList.charge),
            customerWithinMaximumRadios:
            parseFloat(distanceList.customerWithinMaximumRadios),
            customerWithinMinimumRadios:
            parseFloat(distanceList.customerWithinMinimumRadios),
          })
        ),
        basedOnItemSchedules: data?.businessDayes?.map(
          (scheduleList: any, scheduleListId: string) => ({
            businessDayName: scheduleList.businessDay,
            chargeBySchedules: scheduleList?.list?.map(
              (scheduleConfigList: any, scheduleConfigListId: string) => ({
                charge: parseInt(scheduleConfigList.scheduleCharge),
                scheduleDeliveryTimeFrom:
                  scheduleConfigList.scheduleDeliveryTimeFrom,
                scheduleDeliveryTimeTo:
                  scheduleConfigList.scheduleDeliveryTimeTo,
              })
            ),
          })
        ),
        basedOnItemWeights: data?.basedOnItemWeightList?.map(
          (weightList: any, weightListId: string) => ({
            charge: parseInt(weightList.wCharge),
            maximumWeight: parseFloat(weightList.maximumWeight),
            minimumWeight: parseFloat(weightList.minimumWeight),
          })
        ),
        basedOnOrderPrices: data?.basedOnItemPriceList?.map(
          (priceList: any, priceListId: string) => ({
            aboveOrderPrice: parseInt(priceList.aboveOrderPrice),
            charge: parseInt(priceList.priceCharge),
            maximumAboveOrderPrice: parseInt(priceList.maximumAboveOrderPrice),
          })
        ),
        handlingFee: {
          flat: parseInt(data.handlingFreeFlat),
          maxLimit: parseInt(data.handlingFreeMaxLimit),
          percentage: parseInt(data.handlingFreePercentange),
        },
        currencyCode: data.currencyId,
        isConditions: conditionChecked,
        isDistanceBaseCondition:
          conditionType === "basedOnDistances" ? true : false,
        isItemScheduleBaseCondition:
          conditionType === "basedOnItemSchedules" ? true : false,
        isOrderPriceBaseCondition:
          conditionType === "basedOnOrderPrices" ? true : false,
        isWeightBaseCondition:
          conditionType === "basedOnItemWeights" ? true : false,
        //rateId: "string",
        shippingPrice: parseInt(data.shippingPrice),
        shippingTransitTime: {
          from: {
            day: parseInt(data.fromDay),
            hour: parseInt(data.fromHour),
            minute: parseInt(data.fromMinute),
          },
          to: {
            day: parseInt(data.toDay),
            hour: parseInt(data.toHour),
            minute: parseInt(data.toMinute),
          },
        },
        shippingTransitType: transitType,
      },
    };
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/delivery-configuration/add-rate-v2`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("config Create Successfully", "success");
          form.resetFields();
          navigate(-1);
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getCurrencyOptions = useCallback(async (val?: string) => {
    setCurrencyOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/currency/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("res", res);

    setCurrencyOptions({
      loading: false,
      list: res?.data?.currencies?.map((currency: any) => {
        return {
          value: currency?.code,
          label: currency?.code,
        };
      }),
    });
  }, []);

  const getCarriersOptions = useCallback(async (val?: string) => {
    setCarrierOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ORDER_API}/delivery-carrier/all?page=0&limit=20` +
        (val ? `&name=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCarrierOptions({
      loading: false,
      list: res?.data?.deliveryCarriers?.map((carrier: any) => {
        return {
          value: carrier?.id,
          label: carrier?.name,
        };
      }),
    });
  }, []);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "carrier") getCarriersOptions(value);
          if (field === "currency") getCurrencyOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getCarriersOptions, getCurrencyOptions, type]);

  useEffect(() => {
    getCarriersOptions();
    getCurrencyOptions();
  }, [getCarriersOptions, getCurrencyOptions]);
  useEffect(() => {
    if (singleProductInfo?.data) {
      form.resetFields(Object.keys(singleProductInfo?.data));
      form.resetFields();
      setDeliveryCarrierId(singleProductInfo?.data?.deliveryCarrierId);
    }
  }, [form, singleProductInfo?.data]);

  return (
    <React.Fragment>

        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className="">
              <Form
                name="control-hooks"
                labelCol={{ span: 24 }}
                onFinish={onSubmit}
                wrapperCol={{ span: 24 }}
                className={styles.formStyles}
                form={form} // like ref
                layout="horizontal"
              >
                <Card className="mx-8 my-4 rounded-xl">
                  <div className="float-left w-full mt-8">
                    <h3 className="mb-2 text-2xl text-primary">Add Rate</h3>
                  </div>
                  <div className="float-left w-full">
                    <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                  </div>
                  <div className="float-left w-full">
                    <Form.Item
                      hasFeedback
                      name="shippingDeliveryRateType"
                      //initialValue={shippingDeliveryType}
                    >
                      <Radio.Group
                        onChange={(val) =>
                          setShippingRateType((val as any).target.value)
                        }
                        value={shippingRateType || "setUpYourOwnRates"}
                        defaultValue={"setUpYourOwnRates"}
                        name="shippingRateType"
                      >
                        <Radio
                          value={"setUpYourOwnRates"}
                          name="setUpYourOwnRates"
                          className="float-left w-full"
                        >
                          Set up your own rates
                        </Radio>

                        <Radio
                          value="useCarrierOrAppToCalculateRates"
                          className="float-left w-full"
                        >
                          Use carrier or app to calculate rates
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="float-left w-full">
                    <hr className="w-full h-1 mx-auto bg-gray-100 border-0 rounded  dark:bg-gray-700" />
                  </div>

                  {shippingRateType === "useCarrierOrAppToCalculateRates" && (
                    <>
                      <div className="float-left w-full">
                        <div className="float-left w-full">
                          <div className="float-left w-full">
                            <Form.Item
                              hasFeedback
                              label="Carrier name"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Type your country  Zone Name is Required!",
                                },
                              ]}
                              name="name"
                            >
                              <Select
                                showSearch
                                placeholder="Select Carrier"
                                optionFilterProp="children"
                                onSearch={(val) => {
                                  handleSearch(val, "carrier");
                                }}
                                onChange={(val) => setDeliveryCarrierId(val)}
                                filterOption={() => {
                                  return true;
                                }}
                                options={carrierOptions?.list}
                              ></Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="float-left w-full">
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        label="Shipping Transit Type"
                        rules={[
                          {
                            required: false,
                            message: "Shipping Transit Type is Required!",
                          },
                        ]}
                        name="campaignType"
                      >
                        <Select
                          options={[
                            { value: "FREE_DELIVERY", label: "FREE DELIVERY" },
                            {
                              value: "PRIORITY_DELIVERY",
                              label: "PRIORITY DELIVERY",
                            },
                            {
                              value: "STANDARD_DELIVERY",
                              label: "STANDARD DELIVERY",
                            },
                            {
                              value: "ECONOMY_DELIVERY",
                              label: "ECONOMY DELIVERY",
                            },
                          ]}
                          placeholder="Select Transit Type"
                          onChange={(val) => setTransitType(val)}
                        />
                      </Form.Item>
                    </div>

                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        label="Currency Code"
                        name="currencyId"
                        rules={[
                          {
                            required: false,
                            message: "Category is required!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Currency"
                          optionFilterProp="children"
                          onSearch={(val) => {
                            handleSearch(val, "currency");
                          }}
                          filterOption={() => {
                            return true;
                          }}
                          options={currencyOptions?.list}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div className="float-left w-1/2 p-4">
                      Shipping Transit time (From)
                      <span>
                        <Space>
                          <Form.Item
                            hasFeedback
                            label="Day"
                            rules={[
                              {
                                required: false,
                                message: "Day is Required!",
                              },
                            ]}
                            name="fromDay"
                          >
                            <Input
                              type="text"
                              name="fromDay"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="Hour"
                            rules={[
                              {
                                required: false,
                                message: "Hour is Required!",
                              },
                            ]}
                            name="fromHour"
                          >
                            <Input
                              type="text"
                              name="fromHour"
                              placeholder="Hour"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="Minute"
                            rules={[
                              {
                                required: false,
                                message: "Minute is Required!",
                              },
                            ]}
                            name="fromMinute"
                          >
                            <Input
                              type="text"
                              name="fromMinute"
                              placeholder="Minute"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                        </Space>
                      </span>
                    </div>
                    <div className="float-left w-1/2 p-4">
                      Shipping Transit time (TO)
                      <span>
                        <Space>
                          <Form.Item
                            hasFeedback
                            label="Day"
                            rules={[
                              {
                                required: false,
                                message: "Day is Required!",
                              },
                            ]}
                            name="toDay"
                          >
                            <Input
                              type="text"
                              name="toDay"
                              placeholder="Day"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="Hour"
                            rules={[
                              {
                                required: false,
                                message: "Hour is Required!",
                              },
                            ]}
                            name="toHour"
                          >
                            <Input
                              type="text"
                              name="toHour"
                              placeholder="Hour"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="Minute"
                            rules={[
                              {
                                required: false,
                                message: "Minute is Required!",
                              },
                            ]}
                            name="toMinute"
                          >
                            <Input
                              type="text"
                              name="toMinute"
                              placeholder="Minute"
                              style={{ width: 100 }}
                            />
                          </Form.Item>
                        </Space>
                      </span>
                    </div>
                  </div>
                  <div className="float-left w-full">
                    <div className="float-left w-full">
                      <Form.Item
                        hasFeedback
                        label="Shipping Price"
                        rules={[
                          {
                            required: false,
                            message: "Shipping Price is Required!",
                          },
                        ]}
                        name="shippingPrice"
                      >
                        <Input
                          id="shippingPrice"
                          type="text"
                          placeholder="Type your Shipping Price"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {/* new add  */}

                  <div className="float-left w-full">
                    <label>
                      Add Conditions
                      <input
                        name="checkbox"
                        type="checkbox"
                        checked={conditionChecked}
                        onChange={() => {
                          setConditionChecked(!conditionChecked);
                        }}
                      />
                    </label>
                  </div>
                  <div className="float-left w-full">
                    <Form.Item
                      hasFeedback
                      name="shippingDeliveryType"
                      initialValue={shippingDeliveryType}
                    >
                      <Radio.Group
                        onChange={(val) =>
                          setConditionType((val as any).target.value)
                        }
                        value={conditionType}
                        defaultValue={conditionType}
                        disabled={!conditionChecked}
                      >
                        <Radio
                          value={"basedOnItemWeights"}
                          className="float-left w-full"
                        >
                          Based on item weight
                        </Radio>
                        <Radio
                          value={"basedOnOrderPrices"}
                          className="float-left w-full mt-4"
                        >
                          Based on order price
                        </Radio>
                        <Radio
                          value={"basedOnDistances"}
                          className="float-left w-full mt-4"
                        >
                          Based on Distance
                        </Radio>
                        {/* <Radio
                          value={"basedOnAreas"}
                          className="float-left w-full mt-4"
                        >
                          Based on Area
                        </Radio> */}
                        <Radio
                          value={"basedOnItemSchedules"}
                          className="float-left w-full mt-4"
                        >
                          Based on Schedule
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  {conditionType === "basedOnItemWeights" && (
                    <>
                      <div className="float-left w-full">
                        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                          <h3 className="mb-4 text-xl font-bold">
                            Based on item weight
                          </h3>

                          <Form.List
                            name="basedOnItemWeightList"
                            rules={[
                              {
                                validator: async (_, names) => {
                                  if (!names || names.length < 1) {
                                    return Promise.reject(
                                      new Error(
                                        "At Least 1 Based on item weight Required!"
                                      )
                                    );
                                  }
                                },
                              },
                            ]}
                            initialValue={[{}]}
                          >
                            {(
                              basedOnItemWeightFields,
                              { add, remove },
                              { errors }
                            ) => (
                              <>
                                {basedOnItemWeightFields.map(
                                  ({
                                    basedOnItemKey,
                                    name,
                                    baseOnItemFieldKey,
                                    ...baseOnItemRestField
                                  }: any) => (
                                    <Card
                                      className="mb-2"
                                      size="small"
                                      key={basedOnItemKey}
                                    >
                                      <Space wrap align="center">
                                        <Form.Item
                                          {...baseOnItemRestField}
                                          name={[name, "minimumWeight"]}
                                          fieldKey={[
                                            baseOnItemFieldKey,
                                            "minimumWeight",
                                          ]}
                                          hasFeedback
                                          label="Minimum weight"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter minimum weight as like 0 kg" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnItemRestField}
                                          name={[name, "maximumWeight"]}
                                          fieldKey={[
                                            baseOnItemFieldKey,
                                            "maximumWeight",
                                          ]}
                                          hasFeedback
                                          label="Maximum weight"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Maximum weight as like 1 kg" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnItemRestField}
                                          name={[name, "wCharge"]}
                                          fieldKey={[
                                            baseOnItemFieldKey,
                                            "wCharge",
                                          ]}
                                          hasFeedback
                                          label="Charge"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Charge" />
                                        </Form.Item>
                                      </Space>

                                      <Button
                                        type="primary"
                                        danger
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          bottom: 0,
                                        }}
                                        onClick={() => {
                                          remove(name);
                                          setVariationImageUrl((prev) => {
                                            const newArr = prev.filter(
                                              (_, i) => i !== name
                                            );
                                            return newArr;
                                          });
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </Card>
                                  )
                                )}

                                <Form.Item
                                  style={{
                                    width: "100%",
                                    flex: "1 1 100%",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr.push("");
                                        return newArr;
                                      });
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add More
                                  </Button>
                                </Form.Item>

                                <Form.ErrorList errors={errors} />
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </>
                  )}

                  {conditionType === "basedOnOrderPrices" && (
                    <>
                      <div className="float-left w-full">
                        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                          <h3 className="mb-4 text-xl font-bold">
                            Based on order price
                          </h3>

                          <Form.List
                            name="basedOnItemPriceList"
                            rules={[
                              {
                                validator: async (_, names) => {
                                  if (!names || names.length < 1) {
                                    return Promise.reject(
                                      new Error(
                                        "At Least 1 Based on item price Required!"
                                      )
                                    );
                                  }
                                },
                              },
                            ]}
                            initialValue={[{}]}
                          >
                            {(
                              basedOnItemPriceFields,
                              { add, remove },
                              { errors }
                            ) => (
                              <>
                                {basedOnItemPriceFields.map(
                                  ({
                                    basedOnItemPriceKey,
                                    name,
                                    baseOnItemPriceFieldKey,
                                    ...baseOnItemPriceRestField
                                  }: any) => (
                                    <Card
                                      className="mb-2"
                                      size="small"
                                      key={basedOnItemPriceKey}
                                    >
                                      <Space wrap align="center">
                                        <Form.Item
                                          {...baseOnItemPriceRestField}
                                          name={[name, "aboveOrderPrice"]}
                                          fieldKey={[
                                            baseOnItemPriceFieldKey,
                                            "aboveOrderPrice",
                                          ]}
                                          hasFeedback
                                          label="Above Order Price"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Above Order Price" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnItemPriceRestField}
                                          name={[
                                            name,
                                            "maximumAboveOrderPrice",
                                          ]}
                                          fieldKey={[
                                            baseOnItemPriceFieldKey,
                                            "maximumAboveOrderPrice",
                                          ]}
                                          hasFeedback
                                          label="Maximum Above Order Price"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Maximum Above Order Price" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnItemPriceRestField}
                                          name={[name, "priceCharge"]}
                                          fieldKey={[
                                            baseOnItemPriceFieldKey,
                                            "priceCharge",
                                          ]}
                                          hasFeedback
                                          label="Charge"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Charge" />
                                        </Form.Item>
                                      </Space>

                                      <Button
                                        type="primary"
                                        danger
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          bottom: 0,
                                        }}
                                        onClick={() => {
                                          remove(name);
                                          setVariationImageUrl((prev) => {
                                            const newArr = prev.filter(
                                              (_, i) => i !== name
                                            );
                                            return newArr;
                                          });
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </Card>
                                  )
                                )}

                                <Form.Item
                                  style={{
                                    width: "100%",
                                    flex: "1 1 100%",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr.push("");
                                        return newArr;
                                      });
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add More
                                  </Button>
                                </Form.Item>

                                <Form.ErrorList errors={errors} />
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </>
                  )}

                  {conditionType === "basedOnDistances" && (
                    <>
                      <div className="float-left w-full">
                        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                          <h3 className="mb-4 text-xl font-bold">
                            Based on Distance
                          </h3>

                          <Form.List
                            name="basedOnDistanceList"
                            rules={[
                              {
                                validator: async (_, names) => {
                                  if (!names || names.length < 1) {
                                    return Promise.reject(
                                      new Error(
                                        "At Least 1 Based on Distance Required!"
                                      )
                                    );
                                  }
                                },
                              },
                            ]}
                            initialValue={[{}]}
                          >
                            {(
                              basedOnDistanceFields,
                              { add, remove },
                              { errors }
                            ) => (
                              <>
                                {basedOnDistanceFields.map(
                                  ({
                                    basedOnDistanceKey,
                                    name,
                                    baseOnDistanceFieldKey,
                                    ...baseOnDistanceRestField
                                  }: any) => (
                                    <Card
                                      className="mb-2"
                                      size="small"
                                      key={basedOnDistanceKey}
                                    >
                                      <Space wrap align="center">
                                        <Form.Item
                                          {...baseOnDistanceRestField}
                                          name={[
                                            name,
                                            "customerWithinMinimumRadios",
                                          ]}
                                          fieldKey={[
                                            baseOnDistanceFieldKey,
                                            "customerWithinMinimumRadios",
                                          ]}
                                          hasFeedback
                                          label="Customer within Minimum Radios"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Customer within Minimum Radios" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnDistanceRestField}
                                          name={[
                                            name,
                                            "customerWithinMaximumRadios",
                                          ]}
                                          fieldKey={[
                                            baseOnDistanceFieldKey,
                                            "customerWithinMaximumRadios",
                                          ]}
                                          hasFeedback
                                          label="Customer within Maximum Radios"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Customer within Maximum Radios" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnDistanceRestField}
                                          name={[name, "charge"]}
                                          fieldKey={[
                                            baseOnDistanceFieldKey,
                                            "charge",
                                          ]}
                                          hasFeedback
                                          label="Charge"
                                          style={{ width: 350 }}
                                        >
                                          <Input placeholder="Enter Charge" />
                                        </Form.Item>
                                      </Space>

                                      <Button
                                        type="primary"
                                        danger
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          bottom: 0,
                                        }}
                                        onClick={() => {
                                          remove(name);
                                          setVariationImageUrl((prev) => {
                                            const newArr = prev.filter(
                                              (_, i) => i !== name
                                            );
                                            return newArr;
                                          });
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </Card>
                                  )
                                )}

                                <Form.Item
                                  style={{
                                    width: "100%",
                                    flex: "1 1 100%",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr.push("");
                                        return newArr;
                                      });
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add More
                                  </Button>
                                </Form.Item>

                                <Form.ErrorList errors={errors} />
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {conditionType === "basedOnAreas" && (
                    <>
                      <div className="float-left w-full">
                        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                          <h3 className="mb-4 text-xl font-bold">
                            Based on Area
                          </h3>

                          <Form.List
                            name="basedOnAreaList"
                            rules={[
                              {
                                validator: async (_, names) => {
                                  if (!names || names.length < 1) {
                                    return Promise.reject(
                                      new Error(
                                        "At Least 1 Based on area Required!"
                                      )
                                    );
                                  }
                                },
                              },
                            ]}
                            initialValue={[{}]}
                          >
                            {(
                              basedOnAreaFields,
                              { add, remove },
                              { errors }
                            ) => (
                              <>
                                {basedOnAreaFields.map(
                                  ({
                                    basedOnAreaKey,
                                    name,
                                    baseOnAreaFieldKey,
                                    ...baseOnAreaRestField
                                  }: any) => (
                                    <Card
                                      className="mb-2"
                                      size="small"
                                      key={basedOnAreaKey}
                                    >
                                      <Space wrap align="center">
                                        <Form.Item
                                          {...baseOnAreaRestField}
                                          name={[name, "minimumWeight"]}
                                          fieldKey={[
                                            baseOnAreaFieldKey,
                                            "minimumWeight",
                                          ]}
                                          hasFeedback
                                          label="Minimum weight"
                                          style={{ width: 300 }}
                                      
                                        >
                                          <Input placeholder="Enter minimum weight as like 0 kg" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnAreaRestField}
                                          name={[name, "minimumWeight"]}
                                          fieldKey={[
                                            baseOnAreaFieldKey,
                                            "minimumWeight",
                                          ]}
                                          hasFeedback
                                          label="Minimum weight"
                                          style={{ width: 300 }}
                                       
                                        >
                                          <Input placeholder="Enter minimum weight as like 0 kg" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnAreaRestField}
                                          name={[name, "maximumWeight"]}
                                          fieldKey={[
                                            baseOnAreaFieldKey,
                                            "maximumWeight",
                                          ]}
                                          hasFeedback
                                          label="Maximum weight"
                                          style={{ width: 300 }}
                                       
                                        >
                                          <Input placeholder="Enter Maximum weight as like 1 kg" />
                                        </Form.Item>
                                        <Form.Item
                                          {...baseOnAreaRestField}
                                          name={[name, "charge"]}
                                          fieldKey={[
                                            baseOnAreaFieldKey,
                                            "charge",
                                          ]}
                                          hasFeedback
                                          label="Charge"
                                          style={{ width: 300 }}
                                       
                                        >
                                          <Input placeholder="Enter Charge" />
                                        </Form.Item>
                                      </Space>

                                      <Button
                                        type="primary"
                                        danger
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          bottom: 0,
                                        }}
                                        onClick={() => {
                                          remove(name);
                                          setVariationImageUrl((prev) => {
                                            const newArr = prev.filter(
                                              (_, i) => i !== name
                                            );
                                            return newArr;
                                          });
                                        }}
                                      >
                                        <CloseOutlined />
                                      </Button>
                                    </Card>
                                  )
                                )}

                                <Form.Item
                                  style={{
                                    width: "100%",
                                    flex: "1 1 100%",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add();
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr.push("");
                                        return newArr;
                                      });
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add More
                                  </Button>
                                </Form.Item>

                                <Form.ErrorList errors={errors} />
                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </>
                  )} */}

                  {conditionType === "basedOnItemSchedules" && (
                    <>
                      <div className="float-left w-full">
                        <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                          <h3 className="mb-4 text-xl font-bold">
                            Based on Schedule
                          </h3>

                          {/* new schedule add  */}
                          <Form.List name="businessDayes">
                            {(fields, { add, remove }) => (
                              <div
                                style={{
                                  display: "flex",
                                  rowGap: 16,
                                  flexDirection: "column",
                                }}
                              >
                                {fields.map((field) => (
                                  <Card
                                    size="small"
                                    title={`Schedule ${field.name + 1}`}
                                    key={field.key}
                                    extra={
                                      <CloseOutlined
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    }
                                  >
                                    <Form.Item
                                      label="Business Day"
                                      name={[field.name, "businessDay"]}
                                    >
                                      <Select
                                        placeholder="Day"
                                        onChange={(val) => setBusinessDay(val)}
                                        value={businessDay}
                                        style={{ width: 250 }}
                                      >
                                        {Object.values(jcDayArray)?.map(
                                          (dayType, dayIndex) => (
                                            <Option
                                              value={dayType}
                                              key={dayIndex}
                                            >
                                              {dayType.replace("_", " ")}
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>

                                    {/* Nest Form.List */}
                                    <Form.Item label="Based on Schedule Configuration">
                                      <Form.List name={[field.name, "list"]}>
                                        {(subFields, subOpt) => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              rowGap: 16,
                                            }}
                                          >
                                            {subFields.map((subField) => (
                                              <Space key={subField.key}>
                                                <Form.Item
                                                  hasFeedback
                                                  label="Schedule Delivery Time( From)"
                                                  noStyle
                                                  name={[
                                                    subField.name,
                                                    "scheduleDeliveryTimeFrom",
                                                  ]}
                                                >
                                                  <Input placeholder="From Time (12:10)" />
                                                </Form.Item>
                                                <Form.Item
                                                  hasFeedback
                                                  label="Schedule Delivery Time (to)"
                                                  noStyle
                                                  name={[
                                                    subField.name,
                                                    "scheduleDeliveryTimeTo",
                                                  ]}
                                                >
                                                  <Input placeholder="To Time (16:10)" />
                                                </Form.Item>
                                                <Form.Item
                                                  hasFeedback
                                                  label="Charge"
                                                  noStyle
                                                  name={[
                                                    subField.name,
                                                    "scheduleCharge",
                                                  ]}
                                                >
                                                  <Input placeholder="Charge" />
                                                </Form.Item>
                                                <CloseOutlined
                                                  onClick={() => {
                                                    subOpt.remove(
                                                      subField.name
                                                    );
                                                  }}
                                                />
                                              </Space>
                                            ))}
                                            <Button
                                              type="dashed"
                                              onClick={() => subOpt.add()}
                                              block
                                            >
                                              + Add Schedule
                                            </Button>
                                          </div>
                                        )}
                                      </Form.List>
                                    </Form.Item>
                                  </Card>
                                ))}

                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  + Add Business Day
                                </Button>
                              </div>
                            )}
                          </Form.List>
                          {/* new schedule end */}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="float-left w-full">
                    <label>
                      Shipping Handling fee
                      <input
                        name="checkbox"
                        type="checkbox"
                        checked={applyShippingHandlingFee}
                        onChange={() => {
                          setApplyShippingHandlingFee(
                            !applyShippingHandlingFee
                          );
                        }}
                      />
                    </label>
                  </div>
                  <div className="float-left w-full">
                    <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                      Adjust calculated rates to account for packaging and
                      handling costs
                    </p>
                  </div>
                  <div className="float-left w-full">
                    <div className="float-left w-2/6 p-2">
                      <Form.Item
                        hasFeedback
                        label="Percentage"
                        rules={[
                          {
                            required: false,
                            message: "Percentage is Required!",
                          },
                        ]}
                        name="handlingFreePercentange"
                        initialValue={0}
                      >
                        <Input
                          id="handlingFreePercentange"
                          type="number"
                          min={0}
                          placeholder="Type your Percentage"
                          disabled={!applyShippingHandlingFee}
                        />
                      </Form.Item>
                    </div>
                    <div className="float-left w-2/6 p-2">
                      <Form.Item
                        hasFeedback
                        label="Flat Amount"
                        rules={[
                          {
                            required: false,
                            message: "Flat Amount is Required!",
                          },
                        ]}
                        name="handlingFreeFlat"
                        initialValue={0}
                      >
                        <Input
                          id="handlingFreeFlat"
                          type="number"
                          min={0}
                          placeholder="Type your flat amount"
                          disabled={!applyShippingHandlingFee}
                        />
                      </Form.Item>
                    </div>
                    <div className="float-left w-2/6 p-2">
                      <Form.Item
                        hasFeedback
                        label="Max Limit"
                        rules={[
                          {
                            required: false,
                            message: "Max Limit is Required!",
                          },
                        ]}
                        name="handlingFreeMaxLimit"
                        initialValue={0}
                      >
                        <Input
                          id="handlingFreeMaxLimit"
                          type="number"
                          min={0}
                          placeholder="Type your max limit"
                          disabled={!applyShippingHandlingFee}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="float-left w-full">
                    <label>
                      Others Charge
                      <input
                        name="checkbox"
                        type="checkbox"
                        checked={othersCharge}
                        onChange={() => {
                          setOthersCharge(!othersCharge);
                        }}
                      />
                    </label>
                  </div>

                  {othersCharge === true && (
                    <>
                      <Divider plain>Import Charge</Divider>
                      <div className="float-left w-full">
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Percentage"
                            rules={[
                              {
                                required: false,
                                message: "Percentage is Required!",
                              },
                            ]}
                            name="importChargePercentange"
                            initialValue={0}
                          >
                            <Input
                              id="importChargePercentange"
                              type="number"
                              min={0}
                              placeholder="Type your Percentage"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Flat Amount"
                            rules={[
                              {
                                required: false,
                                message: "Flat Amount is Required!",
                              },
                            ]}
                            name="importChargeFlat"
                            initialValue={0}
                          >
                            <Input
                              id="importChargeFlat"
                              type="number"
                              min={0}
                              placeholder="Type your flat amount"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Max Limit"
                            rules={[
                              {
                                required: false,
                                message: "Max Limit is Required!",
                              },
                            ]}
                            name="importChargeMaxLimit"
                            initialValue={0}
                          >
                            <Input
                              id="importChargeMaxLimit"
                              type="number"
                              min={0}
                              placeholder="Type your max limit"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Divider plain>Import Tax</Divider>
                      <div className="float-left w-full">
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Percentage"
                            rules={[
                              {
                                required: false,
                                message: "Percentage is Required!",
                              },
                            ]}
                            name="importTaxPercentange"
                            initialValue={0}
                          >
                            <Input
                              id="importTaxPercentange"
                              type="number"
                              min={0}
                              placeholder="Type your Percentage"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Flat Amount"
                            rules={[
                              {
                                required: false,
                                message: "Flat Amount is Required!",
                              },
                            ]}
                            name="importTaxFlat"
                            initialValue={0}
                          >
                            <Input
                              id="importTaxFlat"
                              type="number"
                              min={0}
                              placeholder="Type your flat amount"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Max Limit"
                            rules={[
                              {
                                required: false,
                                message: "Max Limit is Required!",
                              },
                            ]}
                            name="importTaxMaxLimit"
                            initialValue={0}
                          >
                            <Input
                              id="importTaxMaxLimit"
                              type="number"
                              min={0}
                              placeholder="Type your max limit"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Divider plain>Import Vat</Divider>
                      <div className="float-left w-full">
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Percentage"
                            rules={[
                              {
                                required: false,
                                message: "Percentage is Required!",
                              },
                            ]}
                            name="importVatPercentange"
                            initialValue={0}
                          >
                            <Input
                              id="importVatPercentange"
                              type="number"
                              min={0}
                              placeholder="Type your Percentage"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Flat Amount"
                            rules={[
                              {
                                required: false,
                                message: "Flat Amount is Required!",
                              },
                            ]}
                            name="importVatFlat"
                            initialValue={0}
                          >
                            <Input
                              id="importVatFlat"
                              type="number"
                              min={0}
                              placeholder="Type your flat amount"
                            />
                          </Form.Item>
                        </div>
                        <div className="float-left w-2/6 p-2">
                          <Form.Item
                            hasFeedback
                            label="Max Limit"
                            rules={[
                              {
                                required: false,
                                message: "Max Limit is Required!",
                              },
                            ]}
                            name="importVatMaxLimit"
                            initialValue={0}
                          >
                            <Input
                              id="importVatMaxLimit"
                              type="number"
                              min={0}
                              placeholder="Type your max limit"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  )}
                </Card>

                <div className="w-full mx-8 py-0">
                  <div className="float-left w-11/12 text-right">
                    <SubmitResetBtn
                      onClickReset={() => {
                        form?.resetFields();
                      }}
                      disabled={loading || !!error}
                      loading={loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
};

ShopAddRateList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopAddRateList;



