import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import GetShopBrandsList from "../../Brands";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Button, Drawer, Empty, Pagination, Popconfirm } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import Loader from "../../common/Loader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getPage, getParamValue } from "../../../utils";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { responseNotification } from "../../../utils/notify";
import AddBtn from "../../common/AddBtn";
import moment from "moment";
import SinglePickupZone from "../PickupZone/PickupDetails";
import AddPickupZone from "../PickupZone/AddPickupZone";

const ShopPickupZoneList = (): ReactElement => {
  const route = useParams();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedPickupZone, setSelectedPickupZone] = useState(undefined);
  const [selectedPickupZoneForEdit, setSelectedPickupZoneForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [pickupZoneData, setPickupZoneData] = useState<any>({
    loading: false,
    data: null,
  });

  const getPickupZones = useCallback(async () => {
    setPickupZoneData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/pickup-zone/all?page=0&limit=20`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPickupZoneData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPickupZoneData({ loading: false, data: [] });
        console.error("PickupZones: Error", err);
      });
  }, []);

  //

  const onPickupZoneRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/pickup-zone`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("PickupZone Remove Successfully", "success");
            getPickupZones();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  useEffect(() => {
    getPickupZones();
  }, [getPickupZones]);

  const onClose = () => {
    getPickupZones();
    setVisible(undefined);
    setSelectedPickupZone(undefined);
    setSelectedPickupZoneForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Brand List"
        subTitle="BrandList"
        extra={<></>}
      >
        <>
        <BreadCrumb
          title="Pickup Zone List"
          subTitle={`${
            pickupZoneData?.data?.totalElements ||
            pickupZoneData?.data?.pickupZones?.length ||
            0
          } Zone(s)`}
          extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
        />
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {pickupZoneData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Pickup Zone</th>
                        <th scope="col">Country</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {pickupZoneData?.data?.pickupZones?.length ? (
                        pickupZoneData?.data?.pickupZones?.map(
                          (pickup: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedPickupZone(pickup)}
                            >
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {pickup?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {pickup?.countryName}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {pickup?.state?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {pickup?.id}
                                </span>
                              </td>
                            
                              <td>
                                <small
                                  className={`font-capitalize ${
                                    moment().diff(
                                        pickup?.updatedAt,
                                      "minutes"
                                    ) >= 60
                                      ? "text-red-600"
                                      : "text-gray-800"
                                  }`}
                                >
                                  {moment(pickup?.updatedAt).fromNow()}
                                </small>
                                <br />
                                <span className="name">
                                  {moment(pickup?.updatedAt).format("lll")}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {pickup?.updatedBy?.name ||
                                    pickup?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedPickupZoneForEdit(pickup)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={pickup?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onPickupZoneRemove(pickup?.id);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(pickup?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...pickupZoneData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Pickup Zone"
              : selectedPickupZoneForEdit
              ? "Edit Pickup Zone"
              : "Pickup Zone Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedPickupZone || selectedPickupZoneForEdit}
          width={450}
        >
          {visible || selectedPickupZoneForEdit ? (
            <AddPickupZone
              onCloseMethod={onClose}
              visibleData={selectedPickupZoneForEdit}
            />
          ) : (
            <SinglePickupZone PickupZoneDetails={selectedPickupZone} />
          )}
        </Drawer>
        </>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPickupZoneList.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPickupZoneList;
