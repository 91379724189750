import React, { useCallback, useEffect, useState } from "react";
import CorporateLayout from "../Layout";
import { useSelector } from "react-redux";
import BlanceHistoryList from "../../Finance/CompanyBlanceHistory";
import axios from "axios";
import moment from "moment";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import { Button, DatePicker, Empty, Form, Pagination } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { AddBtn, Loader } from "../../common";
const { RangePicker } = DatePicker;
const CorporateTopUpApprovedList = () => {
  const { token, corporateInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const { corporateId } = corporateInfo?.id;
  const loc = useLocation();
  const pageType: any = getParamValue(loc.search, "page-type");
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(20);
  const [showSearch, setShowSearch] = useState(true);
  let now = new Date();
  let Today = now.toISOString();
  let previous30days = new Date(now.setDate(now.getDate() - 30)).toISOString();

  const [range, setRange] = useState<any>({
    from: previous30days,
    to: Today,
  });

  const [requestTopUpData, setRequestTopUpData] = useState<any>({
    loading: false,
    data: null,
  });
  const getTopUpHistory = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setRequestTopUpData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_TOPUP_API}`;
    axios
      .get(
        `${encodedUri}/admin/topup-request/get-all-by-companyId?companyId=${corporateInfo?.ownerId}` +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == requestTopUpData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRequestTopUpData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setRequestTopUpData({ loading: false, data: [] });
        console.error("Banners: Error", err);
      });
  }, [range, limit, page]);

  //
  const reseAllFieldData = () => {
    form.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };
  useEffect(() => {
    getTopUpHistory();
  }, [getTopUpHistory]);

  return (
    <React.Fragment>
      <CorporateLayout
        corporateId={(route as any)?.corporateId as string}
        title="Company TopUp Request List"
        subTitle="Details"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            {/* topup request list start */}
            <BreadCrumb
              title="Request TopUp List"
              subTitle={`${
                requestTopUpData?.data?.totalElements ||
                requestTopUpData?.data?.companyTopUps?.length ||
                0
              } Topup(s)`}
              extra={[
                <AddBtn
                  onClick={() => {
                    navigate(`/corporates/${corporateInfo?.id}/topup-request`);
                  }}
                  key={2}
                />,
              ]}
            />
            {showSearch && !pageType && (
              <div className={styles?.searchBox}>
                <Form layout="inline" form={form} className={styles.formInline}>
                  <Form.Item name="range">
                    <RangePicker
                      // defaultValue={
                      //   range.from != null && range.to != null
                      //     ? [moment(range.from), moment(range.to)]
                      //     : null
                      // }
                      onChange={(_e, f) => {
                        setRange({
                          from: moment.utc(f[0])?.toISOString(),
                          to: moment.utc(f[1])?.toISOString(),
                        });
                      }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment("00:00:00", "HH:mm:ss"),
                          moment("23:59:59", "HH:mm:ss"),
                        ],
                      }}
                      inputReadOnly
                      allowClear
                    />
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  danger
                  size="large"
                  htmlType="reset"
                  onClick={reseAllFieldData}
                >
                  Reset
                </Button>
              </div>
            )}
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {requestTopUpData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Institute Name</th>
                            <th scope="col">Comment</th>
                            <th scope="col">Number Of Request</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {requestTopUpData?.data?.companyTopUps?.length ? (
                            requestTopUpData?.data?.companyTopUps?.map(
                              (topup: any, index: any) => (
                                <tr
                                  className="border-t hover:bg-gray-100"
                                  key={index}
                                  onClick={() =>
                                    navigate(
                                      `/corporates/${corporateInfo?.id}/approved/${topup?.id}/details`
                                    )
                                  }
                                >
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {moment(topup?.createdAt).format("lll")}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {topup?.instituteName}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {topup?.companyComment}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {topup?.numberOfRequest}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {topup?.totalAmount}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="font-medium text-gray-500 ml-2">
                                      {topup?.status?.split("_").join(" ")}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <Pagination
                {...requestTopUpData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />
            </div>
            {/* topup request list end */}
          </div>
        </div>
      </CorporateLayout>
    </React.Fragment>
  );
};

export default CorporateTopUpApprovedList;
