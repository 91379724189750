import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Switch,
  Image,
  Tooltip,
} from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getQueryParams } from "../../utils";
import { responseNotification } from "../../utils/notify";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../Layouts/Breadcrumb";
import { useSelector } from "react-redux";
import {
  CloseOutlined,
  GiftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ImageUploader, SubmitResetBtn, TreeSelectTwo } from "../common";
import cn from "classnames";
// text editor
// import "jodit";
// import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";

const Option = Select;

const AddProduct = (props: any) => {
  // text editor end
  const editor = useRef(null);
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [priceCalcType, setPriceCalcType] = useState("SHOP_BASED_COMMISSION");
  const [convertedText, setConvertedText] = useState("");
  const [convertedTextForFAQ, setConvertedTextForFAQ] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [saveAndNew, setSaveAndNew] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [variationImageUrl, setVariationImageUrl] = useState<string[]>([]);
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [generatedThumbUrl, setGeneratedThumbUrl] = useState<string>();
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
  const [discountImageUrl, setDiscountImageUrl] = useState<string[]>([]);
  const [skuValue, setSkuValue] = useState<any>();

  //new text editor

  const [discountType, setDiscountType] = useState<{
    [key: string]: {
      type: "disFlat" | "disPercentage" | null;
      value: undefined | number;
    };
  }>({});

  const [qtyDiscountType, setQtyDiscountType] = useState<{
    [key: string]: {
      type: "freeProduct" | "qtyFlat" | "qtyPercentage" | null;
      value: undefined | number;
      minimumQuantity: number;
    };
  }>({});

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded" | any
  >(undefined);

  const [singleProductInfo, setSingleProductInfo] = useState<any>({
    loading: false,
    data: null,
  });

  function productSKU(length: any) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setSkuValue(result.toUpperCase());
    return result;
  }

  useEffect(() => {
    if (skuValue) {
      form.resetFields(["sku"]);
    }
  }, [skuValue]);

  const route = useParams();
  const multi = (getQueryParams() as any)?.for === "multiple-shop";

  const fetchProductDetails = useCallback((productSlug: any) => {
    if (productSlug) {
      try {
        setSingleProductInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/product/details?slug=${productSlug}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleProductInfo({
                loading: false,
                data: data?.data?.product,
              });
              setConvertedText(data?.data?.product?.description || "");
              setConvertedTextForFAQ(data?.data?.product?.faq || "");
              setVariationImageUrl(
                data?.data?.product?.variations?.map(
                  (item: any) => item?.imageUrl
                )
              );

              setDiscountImageUrl(
                data?.data?.product?.variations?.map(
                  (item: any) =>
                    item?.quantitativeProductDiscount?.freeProduct?.productImage
                )
              );
              setIsActive(data?.data?.product?.isActive);
              setGeneratedThumbUrl(data?.data?.product?.productImage);
              setGeneratedImgUrl(data?.data?.product?.images);

              const qtyDis = {} as any;

              setQtyDiscountType(qtyDis);
            } else {
              setSingleProductInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleProductInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleProductInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    if (saveAndNew) {
      setStartUpload("Uploading");
      setStartUpload2("Uploading");
    }
  }, [saveAndNew]);

  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    if (generatedImgUrl || generatedThumbUrl) {
      setLoading(true);
      let readyData = data && {
        barCode: data.barCode,
        sku: data.sku || skuValue,
        name: data.name,
        productOrigin: data.productOrigin,
        type: type,
        shopId: "63ff36b4e69b642c5ee4b751",
        keyword: data.keyword,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        isForCheckOut: true,
        displayOrder: parseInt(data.displayOrder),
        isActive: isActive,
        faq: convertedTextForFAQ || "<p></p>",
        description: convertedText || "<p></p>",
        productImage: generatedThumbUrl,
        images: generatedImgUrl,
        variations: data?.variations?.map((variant: any, i: string) => ({
          variationName: variant.variationName,
          variationId: variant.variationId,
          imageUrl: variant.imageUrl,
          barCode: variant.barCode,
          regularVariationId: data?.variations?.[i + 1]?.variationId,

          maximumOrderLimit: variant.maximumOrderLimit
            ? parseInt(variant.maximumOrderLimit)
            : undefined,
          price: {
            mrp: parseFloat(variant.mrp) * 1,
            tp: parseFloat(variant.mrp) * 1,
          },
          stock: parseFloat(variant.stock) || 0,
        })),
      };

      if (singleProductInfo?.data) {
        await fetch(
          `${process.env.REACT_APP_CATALOG_WRITER_API}/product${
            multi ? `/multiple-shop` : ``
          }`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...readyData,
              id: singleProductInfo?.data?.id,
              // isActive: isActive,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Bag Updated Successfully", "success");
              form.resetFields();
              // if (history?.length === 1) {
              //   navigate(`/products`);
              // } else {
              //   navigate(-1);
              // }
              navigate(-1);
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/product`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            deleted: false,
            isActive: isActive,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Bag Create Successfully", "success");
              if (saveAndNew) {
                console.log(saveAndNew);
              } else {
                form.resetFields();
                navigate(-1);
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    } else {
      responseNotification(`Bag Thumbnail & Image required`, "error");
    }
  };

  // **********************************
  // Search Options
  // **********************************

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [type]);

  // **********************************
  // Search Options

  // **********************************

  useEffect(() => {
    fetchProductDetails((route as any)?.slug);
  }, [fetchProductDetails, route]);

  const resetData = () => {
    form?.resetFields();
    setVariationImageUrl([]);
    setGeneratedImgUrl(singleProductInfo?.data?.images || []);
    setGeneratedThumbUrl(singleProductInfo?.data?.productImage);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <>
      <BreadCrumb
        title={singleProductInfo?.data ? "Edit Bag" : "Add Bag"}
        extra={[
          <Button
            size="large"
            className="bg-green-600 hover:bg-green-500 hover:text-white border-none text-white font-semibold rounded-lg mr-1"
            onClick={(e) => {
              e.preventDefault();
              setSaveAndNew(true);
            }}
            disabled={loading}
            loading={loading}
          >
            Save & New
          </Button>,
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading}
            loading={loading}
          />,
        ]}
      />

      <div className="content-body rounded-2xl">
        <div className="">
          <Form
            name="control-hooks"
            form={form} // like ref
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSubmit}
            initialValues={{
              ...singleProductInfo?.data,
              type: type || singleProductInfo?.data?.shop?.type,
              sku: skuValue || singleProductInfo?.data?.sku,
            }}
            layout="vertical"
            autoComplete="off"
          >
            <div
              className={"grid grid-cols-1 px-2 pt-4 xl:grid-cols-3 xl:gap-6"}
            >
              <div
                className={cn(
                  `grid grid-cols-2 md:grid-cols-2 xl:grid-cols-1 col-span-3 md:col-span-3 lg:col-span-1 gap-6 grid-flow-row auto-rows-max mb-6`
                )}
              >
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl px-4 pb-2">
                  <Divider orientation="left">
                    Thumbnail
                    <Tooltip
                      placement="bottom"
                      title={
                        "Thumbnail Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    // crop={1}
                    imgUrl={generatedThumbUrl || ""}
                    startUpload={startUpload}
                    setStartUpload={(val: any) => setStartUpload(val)}
                    setGeneratedImgUrl={(url: any) => {
                      setGeneratedThumbUrl(url);
                    }}
                  />
                  <Divider orientation="left">
                    Image Gallery
                    <Tooltip
                      placement="bottom"
                      title={
                        "Bag Image should be square. We prefer height 400px and width 400px (Ratio 1)"
                      }
                    >
                      <QuestionCircleOutlined className="px-2 -mt-2" />
                    </Tooltip>
                  </Divider>
                  <ImageUploader
                    multiple
                    imgUrl={generatedImgUrl || []}
                    startUpload={startUpload2}
                    setStartUpload={(val: any) => setStartUpload2(val)}
                    setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
                  />
                </div>
              </div>

              <div className={"grid-cols-3 lg:col-span-2"}>
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">
                    General Info
                  </h3>

                  <div>
                    <Form.Item hasFeedback label="Bag Barcode" name="barCode">
                      <Input
                        id="barCode"
                        type="text"
                        placeholder="Scan Barcode"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Bag Name"
                      rules={[
                        {
                          required: true,
                          message: "Bag Name is Required!",
                        },
                      ]}
                      name="name"
                    >
                      <Input
                        id="name"
                        type="text"
                        placeholder="Enter Bag Name"
                        onBlur={() => productSKU(8)}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Bag Origin"
                      name="productOrigin"
                    >
                      <Input
                        id="productOrigin"
                        type="text"
                        placeholder="Enter Bag Origin"
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="SKU"
                      name="sku"
                      rules={[
                        {
                          required: type !== "JC_FOOD",
                          message: "Bag SKU is Required!",
                        },
                      ]}
                    >
                      <Input id="sku" type="text" placeholder="Enter SKU" />
                    </Form.Item>
                  </div>
                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Display Order"
                      rules={[
                        {
                          required: false,
                          message: "displayOrder is Required!",
                        },
                      ]}
                      name="displayOrder"
                    >
                      <Input
                        id="displayOrder"
                        type="text"
                        placeholder="Enter Display Order"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-span-2 mb-8">
                    <h4 className="font-semibold mb-1">Bag Details</h4>
                    <JoditEditor
                      ref={editor}
                      value={convertedText}
                      config={jodiConfig as any}
                      // tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {}}
                    />
                  </div>
                  <div className="col-span-2 mb-4">
                    <h4 className="font-semibold mb-1">FAQ</h4>
                    <JoditEditor
                      ref={editor}
                      value={convertedTextForFAQ}
                      config={jodiConfig as any}
                      onBlur={(newContent) =>
                        setConvertedTextForFAQ(newContent)
                      }
                      onChange={(newContent) => {}}
                    />
                  </div>
                </div>
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6 col-span-2">
                  <h3 className="mb-4 text-xl font-bold">Variations</h3>

                  <Form.List
                    name="variations"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 1) {
                            return Promise.reject(
                              new Error("At least 1 variation required!")
                            );
                          }
                        },
                      },
                    ]}
                    initialValue={[{}]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }: any) => (
                            <Card className="mb-2" size="small" key={key}>
                              <Space wrap align="center">
                                {/* {variationImageUrl && (
                                      <div>
                                        <Avatar
                                          src={variationImageUrl[name]}
                                          shape="square"
                                          size="large"
                                        />
                                      </div>
                                    )} */}
                                <Form.Item
                                  {...restField}
                                  name={[name, "imageUrl"]}
                                  fieldKey={[fieldKey, "imageUrl"]}
                                  hasFeedback
                                  label="Image"
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.imageUrl
                                  }
                                >
                                  <Input
                                    placeholder="Enter Image"
                                    onChange={async (e) => {
                                      setVariationImageUrl((prev) => {
                                        const newArr = Array.from(prev);
                                        newArr[name] = e.target.value;
                                        return newArr;
                                      });
                                    }}
                                    size="small"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "barCode"]}
                                  fieldKey={[fieldKey, "barCode"]}
                                  hasFeedback
                                  label="Barcode"
                                  style={{ width: 100 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.barCode
                                  }
                                >
                                  <Input placeholder="Enter Barcode" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "variationId"]}
                                  fieldKey={[fieldKey, "variationId"]}
                                  hasFeedback
                                  label="ID"
                                  style={{ width: 80 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.variationId
                                  }
                                >
                                  <Input
                                    id="variationId"
                                    placeholder="Enter ID"
                                    disabled={singleProductInfo?.data}
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "variationName"]}
                                  fieldKey={[fieldKey, "variationName"]}
                                  hasFeedback
                                  label="Variation"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  style={{ width: 90 }}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.variationName
                                  }
                                >
                                  <Input placeholder="1pc/5kg/1pkt" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "mrp"]}
                                  fieldKey={[fieldKey, "mrp"]}
                                  hasFeedback
                                  label="MRP"
                                  style={{ width: 90 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.price?.mrp
                                  }
                                >
                                  <Input placeholder="Enter MRP" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "maximumOrderLimit"]}
                                  fieldKey={[fieldKey, "maximumOrderLimit"]}
                                  hasFeedback
                                  label="Max Limit"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.maximumOrderLimit
                                  }
                                  style={{ width: 80 }}
                                >
                                  <Input placeholder="Maximum Order Limit" />
                                </Form.Item>

                                <Form.Item
                                  {...restField}
                                  name={[name, "stock"]}
                                  fieldKey={[fieldKey, "stock"]}
                                  hasFeedback
                                  label="Stock"
                                  initialValue={
                                    singleProductInfo?.data?.variations?.[name]
                                      ?.stock
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required!",
                                    },
                                  ]}
                                  style={{ width: 70 }}
                                >
                                  <Input placeholder="Stock" />
                                </Form.Item>
                              </Space>

                              <Button
                                type="primary"
                                danger
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  bottom: 0,
                                }}
                                onClick={() => {
                                  remove(name);
                                  setVariationImageUrl((prev) => {
                                    const newArr = prev.filter(
                                      (_, i) => i !== name
                                    );
                                    return newArr;
                                  });
                                }}
                              >
                                <CloseOutlined />
                              </Button>
                            </Card>
                          )
                        )}

                        <Form.Item
                          style={{
                            width: "100%",
                            flex: "1 1 100%",
                            marginBottom: 5,
                          }}
                        >
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              setVariationImageUrl((prev) => {
                                const newArr = Array.from(prev);
                                newArr.push("");
                                return newArr;
                              });
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Variation
                          </Button>
                        </Form.Item>

                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </div>{" "}
                <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6  grid grid-cols-2 gap-x-4">
                  <h3 className="mb-4 text-xl font-bold col-span-2">SEO</h3>

                  <div className="col-span-2">
                    <Form.Item hasFeedback label="Meta Title" name="metaTitle">
                      <Input
                        id="metaTitle"
                        type="text"
                        placeholder="Enter Meta Title"
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item hasFeedback label="Keywords" name="keyword">
                      <Input.TextArea
                        id="keyword"
                        placeholder="Enter Bag Keywords"
                        rows={3}
                      />
                    </Form.Item>
                  </div>

                  <div className="">
                    <Form.Item
                      hasFeedback
                      label="Meta Description"
                      name="metaDescription"
                    >
                      <Input.TextArea
                        id="metaDescription"
                        placeholder="Enter Meta Description"
                        rows={3}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
