import { Checkbox, Form, Input, Divider, Row, Col, Select } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import { useSelector } from "react-redux";
import { Loader, SubmitResetBtn } from "../../common";
import { debounce } from "lodash";
import { getParamValue, jcTypeArray } from "../../../utils";
import { useLocation } from "react-router";

const AddMemberShipUserGroup = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [userType, setUserType] = useState("MERCHANT");
  const [groupsId, setGroupsId] = useState([]);
  const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
  const [levelId, setLevelId] = useState();
  const [loading, setLoading] = useState(false);
  const [memberShipLevelOptions, setMemberShipLevelOptions] = useState({
    loading: false,
    list: [],
  });
  const [memberShipGroupOptions, setMemberShipGroupOptions] = useState({
    loading: false,
    list: [],
  });
  console.log("memberShipLevel ::", memberShipLevelOptions);
  const [memberShipLevelData, setMemberShipLevelData] =
    useState<any>(undefined);

  const [serviceOptions, setServiceOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      membershipId: levelId,
      merchantMembershipGroupActives: groupsId?.map((groupId: any) => ({
        groupId: groupId,
        isActive: true,
      })),
    };
    if (memberShipLevelData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant-group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: memberShipLevelData?.id,
          isActive: memberShipLevelData?.isActive,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Member Ship Level Updated Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant-membership-user-group`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Member Group Create Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getMemberShipLevelOptions = useCallback(
    async (key: any) => {
      setMemberShipLevelOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      return axios
        .get(
          `${encodedUri}/admin/membership/level?userType=${userType}&page=0` +
            (key ? `&key=${key}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setMemberShipLevelOptions({
            loading: false,
            list: res.data?.membershipLevels?.map(
              (memberShipGroup: { name: any; id: any }) => ({
                label: memberShipGroup.name,
                value: memberShipGroup.id,
              })
            ),
          });
        })
        .catch((err) => {
          setMemberShipLevelOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [userType, page, limit]
  );
  const getMemberShipGroupOptions = useCallback(async (key: any) => {
    setMemberShipGroupOptions({ loading: true, list: [] });
    const encodedUrl = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(`${encodedUrl}/merchant-group/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMemberShipGroupOptions({
          loading: false,
          list: res.data?.merchantGroups?.map(
            (merchantGroup: { name: any; id: any }) => ({
              label: merchantGroup.name,
              value: merchantGroup.id,
            })
          ),
        });
      })
      .catch((err) => {
        setMemberShipGroupOptions({ loading: false, list: [] });
        console.error("Products: Error", err);
      });
  }, []);
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "lavel") getMemberShipLevelOptions(value);
        if (field === "group") getMemberShipGroupOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getMemberShipLevelOptions, getMemberShipGroupOptions]);

  useEffect(() => {
    getMemberShipLevelOptions("");
    getMemberShipGroupOptions("");
  }, [getMemberShipLevelOptions, getMemberShipGroupOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...memberShipLevelData,
        }}
      > 
      <Form.Item
          hasFeedback
          label="Service Type"
          rules={[
            {
              required: false,
              message: "Type is required!",
            },
          ]}
          name="serviceType"
          initialValue={"JC_COMMERCE"}
        >
          <Select
            placeholder="Service Type"
            onChange={(val) => setServiceType(val)}
          >
            {Object.values(jcTypeArray)?.map((type, i) => (
              <Select.Option value={type} key={i}>
                {type?.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="lave_search" label="MemberShip Lavel">
          <Select
            allowClear
            showSearch
            placeholder={`Filter by  Lavel`}
            optionFilterProp="children"
            onChange={(val) => setLevelId(val)}
            // onSearch={(e) => handleSearch(e)}
            filterOption={() => {
              return true;
            }}
            options={memberShipLevelOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item name="lave_Group" label="MemberShip Group">
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder={`Filter by  Group`}
            optionFilterProp="children"
            onChange={(val) => setGroupsId(val)}
            // onSearch={(e) => handleSearch(e)}
            filterOption={() => {
              return true;
            }}
            options={memberShipGroupOptions?.list}
          ></Select>
        </Form.Item>

        <Divider />
        <SubmitResetBtn
          onClickReset={() => {
            form?.resetFields();
          }}
          disabled={loading || !!error}
          loading={loading}
        />
      </Form>
    </>
  );
};

export default AddMemberShipUserGroup;
