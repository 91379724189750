import { Divider, Form, Image, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ReactElement } from "react";
import styles from "../../../styles/tailwind/Add.module.css";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import axios from "axios";
import { SubmitResetBtn } from "../../common";

const OtherCharge = ({
  OtherChargeDetails,
  onCloseMethod,
}: {
  OtherChargeDetails: any;
  onCloseMethod:any;
}): ReactElement => {
  console.log("OtherChargeDetails ==", OtherChargeDetails);
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [othersCharge, setOthersCharge] = useState(false);
  const [otherChrageData, setOtherChargeData] = useState<any>({
    loading: false,
    data: null,
  });
  console.log("otherChrageData ===", otherChrageData);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      othersCharge: {
        importCharge: {
          flat: parseInt(data.importChargeFlat),
          maxLimit: parseInt(data.importChargeMaxLimit),
          percentage: parseInt(data.importChargePercentange),
        },
        importTax: {
          flat: parseInt(data.importTaxFlat),
          maxLimit: parseInt(data.importTaxMaxLimit),
          percentage: parseInt(data.importTaxPercentange),
        },
        importVat: {
          flat: parseInt(data.importVatFlat),
          maxLimit: parseInt(data.importVatMaxLimit),
          percentage: parseInt(data.importVatPercentange),
        },
      },
      hasOtherCharge: othersCharge,
    };
    await fetch(`${process.env.REACT_APP_ORDER_API}/delivery-configuration/update-other-charge`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
        id: OtherChargeDetails?.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Other Charge Updated Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchOtherChargeDetails = useCallback(async () => {
    setOtherChargeData({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/delivery-configuration/detail?configurationId=${OtherChargeDetails?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setOtherChargeData({
            loading: false,
            data: data?.data?.deliveryConfiguration,
          });
        } else {
          setOtherChargeData({ loading: false, data: null });
          responseNotification(
            data.statusText || "something went wrong",
            "error"
          );
        }
      })
      .catch((err) => {
        setOtherChargeData({ loading: false, data: null });
      });
  }, [OtherChargeDetails?.id]);

  useEffect(() => {
    fetchOtherChargeDetails();
  }, [fetchOtherChargeDetails]);
  useEffect(() => {
    if (otherChrageData?.data) {
      form.resetFields(Object.keys(otherChrageData?.data));
      form.resetFields();
      setOthersCharge(otherChrageData?.data?.hasOtherCharge);
    }
  }, [form, otherChrageData?.data]);
  return (
    <>
      <div className="">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.formStyles}
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          initialValues={{
            ...otherChrageData?.data,
            importChargePercentange:
              otherChrageData?.data?.othersCharge?.importCharge?.percentage ||
              0,
            importChargeFlat:
              otherChrageData?.data?.othersCharge?.importCharge?.flat || 0,
            importChargeMaxLimit:
              otherChrageData?.data?.othersCharge?.importCharge?.maxLimit || 0,
            importTaxPercentange:
              otherChrageData?.data?.othersCharge?.importTax?.percentage || 0,
            importTaxFlat:
              otherChrageData?.data?.othersCharge?.importTax?.flat || 0,
            importTaxMaxLimit:
              otherChrageData?.data?.othersCharge?.importTax?.maxLimit || 0,
            importVatPercentange:
              otherChrageData?.data?.othersCharge?.importVat?.percentage || 0,
            importVatFlat:
              otherChrageData?.data?.othersCharge?.importVat?.flat || 0,
            importVatMaxLimit:
              otherChrageData?.data?.othersCharge?.importVat?.maxLimit || 0,
          }}
        >
          <div className="float-left w-full">
            <label>
              Others Charge
              <input
                name="checkbox"
                type="checkbox"
                checked={othersCharge}
                onChange={() => {
                  setOthersCharge(!othersCharge);
                }}
              />
            </label>
          </div>
          <Divider plain>Import Charge</Divider>
          <div className="float-left w-full">
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Percentage"
                rules={[
                  {
                    required: false,
                    message: "Percentage is Required!",
                  },
                ]}
                name="importChargePercentange"
              >
                <Input
                  id="importChargePercentange"
                  type="number"
                  min={0}
                  placeholder="Type your Percentage"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Flat Amount"
                rules={[
                  {
                    required: false,
                    message: "Flat Amount is Required!",
                  },
                ]}
                name="importChargeFlat"
              >
                <Input
                  id="importChargeFlat"
                  type="number"
                  min={0}
                  placeholder="Type your flat amount"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Max Limit"
                rules={[
                  {
                    required: false,
                    message: "Max Limit is Required!",
                  },
                ]}
                name="importChargeMaxLimit"
              >
                <Input
                  id="importChargeMaxLimit"
                  type="number"
                  min={0}
                  placeholder="Type your max limit"
                />
              </Form.Item>
            </div>
          </div>
          <Divider plain>Import Tax</Divider>
          <div className="float-left w-full">
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Percentage"
                rules={[
                  {
                    required: false,
                    message: "Percentage is Required!",
                  },
                ]}
                name="importTaxPercentange"
              >
                <Input
                  id="importTaxPercentange"
                  type="number"
                  min={0}
                  placeholder="Type your Percentage"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Flat Amount"
                rules={[
                  {
                    required: false,
                    message: "Flat Amount is Required!",
                  },
                ]}
                name="importTaxFlat"
              >
                <Input
                  id="importTaxFlat"
                  type="number"
                  min={0}
                  placeholder="Type your flat amount"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Max Limit"
                rules={[
                  {
                    required: false,
                    message: "Max Limit is Required!",
                  },
                ]}
                name="importTaxMaxLimit"
              >
                <Input
                  id="importTaxMaxLimit"
                  type="number"
                  min={0}
                  placeholder="Type your max limit"
                />
              </Form.Item>
            </div>
          </div>
          <Divider plain>Import Vat</Divider>
          <div className="float-left w-full">
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Percentage"
                rules={[
                  {
                    required: false,
                    message: "Percentage is Required!",
                  },
                ]}
                name="importVatPercentange"
              >
                <Input
                  id="importVatPercentange"
                  type="number"
                  min={0}
                  placeholder="Type your Percentage"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Flat Amount"
                rules={[
                  {
                    required: false,
                    message: "Flat Amount is Required!",
                  },
                ]}
                name="importVatFlat"
              >
                <Input
                  id="importVatFlat"
                  type="number"
                  min={0}
                  placeholder="Type your flat amount"
                />
              </Form.Item>
            </div>
            <div className="float-left w-2/6 p-2">
              <Form.Item
                hasFeedback
                label="Max Limit"
                rules={[
                  {
                    required: false,
                    message: "Max Limit is Required!",
                  },
                ]}
                name="importVatMaxLimit"
              >
                <Input
                  id="importVatMaxLimit"
                  type="number"
                  min={0}
                  placeholder="Type your max limit"
                />
              </Form.Item>
            </div>
          </div>
          <div className="w-full mx-8 py-0">
            <div className="float-left w-11/12 text-right">
              <SubmitResetBtn
                onClickReset={() => {
                  form?.resetFields();
                }}
                disabled={loading || !!error}
                loading={loading}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default OtherCharge;
